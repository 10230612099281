import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Box, ButtonInner, CustomCheckboxWrap, Wrapper } from './MainInfo.styled';

import { cn } from '../../../../../../shared/lib';
import { IUserServices } from '../../../../../../shared/types/user/user.interface';
import { TextPType, TextType, Text, Input, InputType, MultiSelect } from '../../../../../../shared/ui';
import CommonButton from '../../../../../../shared/ui/Button/ui/CommonButton';
import Dropdown from '../../../../../../shared/ui/Dropdown/ui/Dropdown';
import { DirectionType, PositionType } from '../../../../../../shared/ui/MultiSelect/ui/MultiSelect.props';
import Tooltip from '../../../../../../shared/ui/Tooltip/ui/Tooltip';
import { useProductsIdsQuery } from '../../../../../../store/redux/products/hooks/useProductsQuery';
import { useSubproductAllQuery } from '../../../../../../store/redux/subproducts/hooks/useSubproductQuery';
import { ISubproduct } from '../../../../../../store/redux/subproducts/subproducts.interface';
import { useUpdateUser } from '../../../../../../store/redux/user/hooks/useUsersMutation';
import { MODAL_GAP, MODAL_TEXT_GAP } from '../../../../../../styles';
import { getCookie } from '../../../../../../utils/cookies';
import { FlexWithAlign, FormStyle } from '../../../../../../utils/styleUtils';
import { EIcon, IconNew as IconInstance } from '../../../../../icons/medium-new-icons/icon';

interface IProps {
  modalPayload?: any;
  formik: any;
  initialValues: any;
  closeModal: () => void;
}
const roleArray = [
  { label: 'Администратор', value: 'administrator' },
  { label: 'Менеджер', value: 'manager' }
];
export const MainInfo: FC<IProps> = (props) => {
  const { modalPayload, formik, initialValues, closeModal } = props;
  const { t } = useTranslation();
  const { data: ids } = useProductsIdsQuery();
  const { data: subproductsAll } = useSubproductAllQuery(ids);
  const { mutateAsync: updateUser, isPending } = useUpdateUser();
  const [pending, setPending] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [selectedServices, setSelectedServices] = useState<(Pick<ISubproduct, 'id' | 'name'> | undefined)[]>(subproductsAll);
  useEffect(() => {
    formik.initialValues = initialValues;
  }, []);
  useEffect(() => {
    if (modalPayload && modalPayload.idservices) {
      const { idservices } = modalPayload;
      setSelectedServices(
        subproductsAll?.filter((item) => idservices.findIndex((itemService: IUserServices) => itemService.idservice === item?.id) > -1)
      );
    }
  }, [subproductsAll]);

  useEffect(() => {
    if (formik.values.fio && formik.values.email && formik.values.phone && (formik.values.idfilial || formik.values.idfilial === '')) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formik.values]);

  const editEmployee = async (values: any) => {
    setPending(true);
    const offlineConvert = values.offline ? 'no' : 'yes';
    const { role, ...updatedValues } = values;
    const res = await updateUser({
      ...updatedValues,
      idservices: values.services
        ? selectedServices.map((item) => {
            return {
              idservice: item?.id
            };
          })
        : [],
      offline: offlineConvert,
      role: role ? role : ''
    });

    if (res?.status === 'ok') {
      toast.success('Данные о сотруднике обновлены');
      closeModal();
    } else {
      setPending(false);
      toast.error(res.description);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (
      !formValid ||
      formik.values.fio === '' ||
      formik.values.email === '' ||
      formik.values.phone === '' ||
      (formik.values.offline === true && formik.values.role === '') ||
      isPending
    ) {
      if (formik.values.fio === '') {
        setNameError(true);
      }
      if (formik.values.email === '') {
        setEmailError(true);
      }
      if (formik.values.phone === '') {
        setPhoneError(true);
      }
      if (formik.values.role === '' && formik.values.offline === true) {
        setRoleError(true);
      }

      return;
    }
    editEmployee(formik.values);
  };
  useEffect(() => {
    if (nameError) {
      formik.values.fio !== '' && setNameError(false);
    }
  }, [formik.values.fio]);
  useEffect(() => {
    if (phoneError) {
      formik.values.phone !== '' && setPhoneError(false);
    }
  }, [formik.values.phone]);
  useEffect(() => {
    if (emailError) {
      formik.values.email !== '' && setEmailError(false);
    }
  }, [formik.values.email]);
  useEffect(() => {
    if (roleError) {
      formik.values.role !== '' && setRoleError(false);
    }
  }, [formik.values.role]);

  useEffect(() => {
    if (modalPayload.avatar !== formik.values.avatar) {
      const offlineConvert = formik.values.offline ? 'no' : 'yes';
      const { role, ...updatedValues } = formik.values;
      updateUser({
        ...updatedValues,
        idservices: formik.values.services
          ? selectedServices.map((item) => {
              return {
                idservice: item?.id
              };
            })
          : [],
        offline: offlineConvert
      });
    }
  }, [formik.values.avatar]);

  return (
    <Wrapper>
      <FlexWithAlign>
        <FormStyle
          onSubmit={handleSubmit}
          className={'form-wrapper'}
        >
          <Box className='form'>
            <Input
              size={InputType.M}
              label={t('Имя Фамилия сотрудника*')}
              value={formik.values.fio}
              onChange={formik.handleChange}
              name='fio'
              onBlur={formik.handleBlur}
              error={formik.errors.fio || (nameError && 'Введите Имя и Фамилию сотрудника')}
            />

            <Input
              size={InputType.M}
              label={t('Телефон*')}
              name='phone'
              value={formik.values.phone}
              onChange={formik.handleChange}
              type={'tel'}
              onBlur={formik.handleBlur}
              error={formik.errors.phone || (phoneError && 'Введите телефон')}
            />
            {modalPayload && modalPayload?.id !== getCookie('id') && (
              <Input
                size={InputType.M}
                label={'Email*'}
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                type={'email'}
                onBlur={formik.handleBlur}
                error={formik.errors.email || (emailError && 'Введите email')}
              />
            )}
            <Input
              size={InputType.M}
              label={t('Должность')}
              name='position'
              value={formik.values.position}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.position && formik.errors.position}
            />

            <FlexWithAlign
              $column
              $gap={MODAL_TEXT_GAP}
            >
              <FlexWithAlign
                $align='center'
                $gap='8px'
              >
                <Text
                  $tag={TextType.P}
                  $pSize={TextPType.P15}
                >
                  {t('Сотрудник оказывает услуги')}?
                </Text>
                <Tooltip
                  text='Данный сотрудник оказывает услуги клиентам и будет отображаться в журнале записей'
                  icon={<IconInstance name={EIcon.questionempty} />}
                />
              </FlexWithAlign>

              <FlexWithAlign
                className={'full'}
                $gap={MODAL_GAP}
              >
                <CustomCheckboxWrap
                  onClick={(e) => {
                    e.preventDefault();
                    formik.setFieldValue('services', true);
                  }}
                >
                  <div className={cn('check-wrapper', formik.values.services === true && 'active')}>
                    <span></span>
                  </div>
                  <Text
                    $tag={TextType.P}
                    $pSize={TextPType.P15}
                  >
                    {t('Да')}
                  </Text>
                </CustomCheckboxWrap>
                <CustomCheckboxWrap
                  onClick={(e) => {
                    e.preventDefault();
                    formik.setFieldValue('services', false);
                  }}
                >
                  <div className={cn('check-wrapper', formik.values.services === false && 'active')}>
                    <span></span>
                  </div>
                  <Text
                    $tag={TextType.P}
                    $pSize={TextPType.P15}
                  >
                    {t('Нет')}
                  </Text>
                </CustomCheckboxWrap>
              </FlexWithAlign>
            </FlexWithAlign>
            {formik.values.services && (
              <MultiSelect
                direction={DirectionType.VERTICAL}
                size={InputType.M}
                optionPosition={PositionType.BOTTOM}
                options={subproductsAll ? subproductsAll : []}
                value={selectedServices}
                setValue={setSelectedServices}
                placeholder={t('Услуги')}
              />
            )}
            {modalPayload.role !== 'admin' && (
              <div className='inviteemployeer'>
                <input
                  type='checkbox'
                  name='offline'
                  value={'false'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className='inviteemployeer-input'
                  disabled={(modalPayload && modalPayload?.offline === 'no') || (modalPayload && modalPayload?.role === 'manager')}
                  defaultChecked={formik.values?.offline === true}
                />
                <span className={cn(formik.values?.offline === true && 'active', 'custom-check')}>
                  <IconInstance name={EIcon.success} />
                </span>
                <Text
                  $tag={TextType.P}
                  $pSize={TextPType.P15}
                >
                  {t('Пригласить в систему')}
                </Text>
                <Tooltip
                  text='Сотруднику будет направлен пароль от личного кабинета на электронную почту'
                  icon={<IconInstance name={EIcon.questionempty} />}
                />

                <div className={'icon-wrap flex'}></div>
              </div>
            )}

            {formik.values.offline === true && (
              <Dropdown
                size={InputType.M}
                onChange={(option: any) => {
                  formik.setFieldValue('role', option.value);
                }}
                error={roleError && 'Выберите Роль сотрудника'}
                position={'top'}
                options={formik.values.id === getCookie('id') ? [{ label: 'Владелец', value: 'admin' }] : roleArray}
                currentValue={roleArray.find((item) => item.label === formik.values.role)?.label || formik.values.role}
                labelTitle={t('Роль в системе')}
                formik={formik}
                disabled={modalPayload.role !== ''}
              />
            )}

            <FlexWithAlign
              $align='center'
              $gap={MODAL_GAP}
              $justify='flex-start'
            >
              <CommonButton
                typeBtn='gray'
                size={'M'}
                fullWidth={false}
                type='button'
                onClick={(e) => {
                  e.preventDefault();
                  closeModal();
                }}
              >
                {t('Отменить')}
              </CommonButton>
              <CommonButton
                typeBtn='primary'
                size={'M'}
                fullWidth={false}
              >
                <ButtonInner>
                  <span>{t('Сохранить')}</span>
                </ButtonInner>
              </CommonButton>
            </FlexWithAlign>
          </Box>
        </FormStyle>
      </FlexWithAlign>
    </Wrapper>
  );
};
