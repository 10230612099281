import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import { hours } from './EmployeeSchedule.data';
import {
  Box,
  Container,
  Hours,
  LoaderLayout,
  Side,
  Slot,
  Table,
  Wrapper,
  Event,
  BtnControlWrap,
} from './EmployeeSchedule.styled';

import { useActions } from '../../../../../../shared/lib/hooks/useActions';
import { useTypedSelector } from '../../../../../../shared/lib/hooks/useTypedSelector';
import CommonButton from '../../../../../../shared/ui/Button/ui/CommonButton';
import { getMonday } from '../../../../../../store/redux/calendar/calendar.actions';
import { useUpdateUserSchedule } from '../../../../../../store/redux/user/hooks/useUsersMutation';
import { useUserScheduleQuery } from '../../../../../../store/redux/user/hooks/useUsersQuery';
import { IUserSchedule } from '../../../../../../store/redux/user/user.interface';
import { addDateBy, calculateEventWidth, formatDate, generateDaysArray, timeStringToNumber } from '../../../../../../utils/date-events';
import {
  generateRandomString,
  numberToTime,
  transformEmployeeScheduleData,
  transformEmployeeSheduleToApiData
} from '../../../../../../utils/helperFunctions';
import { ISlot } from '../../../../../../utils/initialSchedule';
import { PENDING, STATE } from '../../../../../../utils/state';
import CommonLoader from '../../../../loader/CommonLoader';

interface IProps {
  schedule?: ISlot[][];
  state?: STATE;
  onSave?: (data: any[]) => void;
  employeeId: string;
  closeModal: () => void;
}

const EmployeeSchedule: React.FC<IProps> = (props) => {
  const { state, employeeId, closeModal } = props;
  const { activeDate, chosenWeek } = useTypedSelector((state1) => state1.calendar);
  const { chosenEmployeeScheduleSlots } = useTypedSelector((state) => state.modal);
  const { setWeek, updateEmployeeScheduleSlots } = useActions();
  const { state: stateUser } = useTypedSelector((state1) => state1.user);

  const {
    data: currEmployeeSchedule,
    isLoading,
    isFetched
  } = useUserScheduleQuery({
    id: employeeId,
    dateStart: chosenWeek[0],
    dateEnd: chosenWeek[chosenWeek.length - 1]
  });
  const { mutateAsync: updateSchedule } = useUpdateUserSchedule();
  const { t } = useTranslation();
  const [mondayDate, setMondayDate] = useState(getMonday(new Date(activeDate)));
  const days = [
    { title: 'пн', action: mondayDate?.toDateString(), time: [] },
    { title: 'вт', action: addDateBy(mondayDate, 1).toDateString(), time: [] },
    { title: 'ср', action: addDateBy(mondayDate, 2).toDateString(), time: [] },
    { title: 'чт', action: addDateBy(mondayDate, 3).toDateString(), time: [] },
    { title: 'пт', action: addDateBy(mondayDate, 4).toDateString(), time: [] },
    { title: 'сб', action: addDateBy(mondayDate, 5).toDateString(), time: [] },
    { title: 'вс', action: addDateBy(mondayDate, 6).toDateString(), time: [] }
  ];
  // const [selectedEvent, setSelectedEvent] = useState<any>(null);
  // const [eventData, setEventData] = useState<{
  //   day: string;
  //   date: string;
  //   time: string;
  //   timeEnd: string;
  // }>({ day: '', date: '', time: '', timeEnd: '' });
  const [events, setEvents] = useState<{ day: string; date: string; time: string; timeEnd: string; start?: string; end?: string }[]>(
    chosenEmployeeScheduleSlots.filter((item) => chosenWeek.find((day) => day === item.date))
  );
  // const [slotTime, setSlotTime] = useState<{ day: string; date: string; time: string; timeEnd: string; start?: string; end?: string }[]>(
  //   []
  // );
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const handleSlotClick = (day: any, date: any, time: string) => {
    setIsEdit(true);
    const startDateTime = new Date(`${date} ${time}`);
    const endDateTime = new Date(startDateTime);
    endDateTime.setHours(startDateTime.getHours() + 1);
    const sliceEndTime = `${endDateTime.getHours().toString().padStart(2, '0')}:00`;

    // setEventData({
    //   day,
    //   date,
    //   time,
    //   timeEnd: sliceEndTime
    // });

    const newEvent: any = {
      id: generateRandomString(),
      day,
      date,
      time,
      timeEnd: sliceEndTime
    };

    const currIndex = events.findIndex((event) => event.day === newEvent.day && event.time === newEvent.time);
    let maxRowTime = newEvent.time;
    let minRowTime = newEvent.time;
    if (events.find((event) => event.date === newEvent.date && event.time !== '')) {
      maxRowTime = events.filter((event) => event.date === newEvent.date).reduce((acc, curr) => (acc.time > curr.time ? acc : curr)).time;
      minRowTime = events.filter((event) => event.date === newEvent.date).reduce((acc, curr) => (acc.time < curr.time ? acc : curr)).time;
    }

    if (currIndex === -1) {
      if (maxRowTime < newEvent.time) {
        setEvents((prevEvents) => [
          ...prevEvents,
          ...days
            .filter((day) => day.action === newEvent.date)[0]
            .time.filter(
              (item: { title: string }) =>
                timeStringToNumber(item.title) > timeStringToNumber(maxRowTime) &&
                timeStringToNumber(item.title) <= timeStringToNumber(newEvent.time)
            )
            .map((item: { title: string }) => {
              return {
                id: generateRandomString(),
                day: newEvent.day,
                date: newEvent.date,
                time: item.title,
                timeEnd: numberToTime(timeStringToNumber(item.title) + 1)
              };
            })
        ]);
      } else if (minRowTime > newEvent.time) {
        setEvents((prevEvents) => [
          ...prevEvents,
          ...days
            .filter((day) => day.action === newEvent.date)[0]
            .time.filter(
              (item: { title: string }) =>
                timeStringToNumber(item.title) < timeStringToNumber(minRowTime) &&
                timeStringToNumber(item.title) >= timeStringToNumber(newEvent.time)
            )
            .map((item: { title: string }) => {
              return {
                id: generateRandomString(),
                day: newEvent.day,
                date: newEvent.date,
                time: item.title,
                timeEnd: numberToTime(timeStringToNumber(item.title) + 1)
              };
            })
        ]);
      } else {
        setEvents((prevEvents) => [...prevEvents.filter((item) => item.date !== newEvent.date), newEvent]);
      }
    } else {
      if (minRowTime === newEvent.time) {
        minRowTime === maxRowTime
          ? setEvents((prevEvents) => [
              ...prevEvents
                .sort((x, y) => x.time.localeCompare(y.time))
                .filter((item) => item.date !== newEvent.date || item.time !== newEvent.time),
              { ...newEvent, time: '', timeEnd: '' }
            ])
          : setEvents((prevEvents) => [
              ...prevEvents
                .sort((x, y) => x.time.localeCompare(y.time))
                .filter((item) => item.date !== newEvent.date || item.time !== newEvent.time)
            ]);
      } else if (maxRowTime === newEvent.time) {
        minRowTime === maxRowTime
          ? setEvents((prevEvents) => [
              ...prevEvents
                .sort((x, y) => x.time.localeCompare(y.time))
                .filter((item) => item.date !== newEvent.date || item.time !== newEvent.time),
              { ...newEvent, time: '', timeEnd: '' }
            ])
          : setEvents((prevEvents) => [
              ...prevEvents
                .sort((x, y) => x.time.localeCompare(y.time))
                .filter((item) => item.date !== newEvent.date || item.time !== newEvent.time)
            ]);
      } else {
        setEvents((prevEvents) => [
          ...prevEvents
            .sort((x, y) => x.time.localeCompare(y.time))
            .filter((item) => item.date !== newEvent.date || item.time < newEvent.time)
        ]);
      }
    }
  };

  for (let i = 0; i < days.length; i++) {
    const day = days[i];
    for (let hour = 7; hour < 24; hour++) {
      const formattedHour = hour.toString().padStart(2, '0');
      //@ts-ignore
      day.time.push({ title: `${formattedHour === 24 ? '00' : formattedHour}:00` });
    }
  }

  useEffect(() => {
    setWeek(generateDaysArray(getMonday(new Date(activeDate)), addDateBy(getMonday(new Date(activeDate)), 6)));
    setMondayDate(getMonday(new Date(activeDate)));
  }, [activeDate]);

  useEffect(() => {
    if ((stateUser === 'FULFILLED' || stateUser === 'REJECTED') && currEmployeeSchedule) {
      if (isEdit) {
        setEvents((prevEvents) => [
          // ...prevEvents.filter((item) => !chosenEmployeeScheduleSlots.find((slot) => slot.date === item.date)),
          ...transformEmployeeScheduleData(currEmployeeSchedule).filter(
            (item) => !chosenEmployeeScheduleSlots.find((slot) => slot.date === item.date)
          ),
          ...chosenEmployeeScheduleSlots.filter((item) => chosenWeek.find((day) => day === item.date))
        ]);
      } else {
        setEvents((prevEvents) => [
          // ...prevEvents.filter((item) => !chosenEmployeeScheduleSlots.find((slot) => slot.date === item.date)),
          ...transformEmployeeScheduleData(currEmployeeSchedule),
          ...chosenEmployeeScheduleSlots.filter(
            (item) =>
              chosenWeek.find((day) => day === item.date) &&
              !transformEmployeeScheduleData(currEmployeeSchedule).find((currEmpSchedule) => currEmpSchedule.date === item.date)
          )
        ]);
      }
      // setEvents((prevEvents) => [
      //   // ...prevEvents.filter((item) => !chosenEmployeeScheduleSlots.find((slot) => slot.date === item.date)),
      //   ...transformEmployeeScheduleData(currEmployeeSchedule).filter(
      //     (item) => !chosenEmployeeScheduleSlots.find((slot) => slot.date === item.date)
      //   ),
      //   ...chosenEmployeeScheduleSlots.filter((item) => chosenWeek.find((day) => day === item.date))
      // ]);
      // updateEmployeeScheduleSlots([...transformEmployeeScheduleData(currEmployeeSchedule)]);
    }
  }, [stateUser, currEmployeeSchedule]);

  useEffect(() => {
    if ((!isLoading || isFetched) && currEmployeeSchedule) {
      updateEmployeeScheduleSlots({ data: [...transformEmployeeScheduleData(currEmployeeSchedule)], isEdit: isEdit });
    }
  }, [isLoading, isFetched]);
  useEffect(() => {
    updateEmployeeScheduleSlots({ data: events, isEdit: isEdit });
  }, [events]);

  const saveSchedules = async (values: any) => {
    if (!currEmployeeSchedule) return;
    const scheduleArr = transformEmployeeSheduleToApiData(values);
    const initialSchedule = transformEmployeeSheduleToApiData(transformEmployeeScheduleData(currEmployeeSchedule));

    const excludeArr = initialSchedule
      .filter((item: any) => scheduleArr.findIndex((it: IUserSchedule) => it.date === item.date) === -1)
      .map((item: any) => {
        return {
          date: item.date,
          timestart: '',
          timeend: ''
        };
      });
    const resultArr = [...scheduleArr, ...excludeArr];

    const response = await updateSchedule({
      id: employeeId,
      data: resultArr.map((item) => {
        return {
          date: dayjs(item.date).format('YYYY-MM-DD'),
          timestart: item.timestart,
          timeend: item.timeend
        };
      })
    });
    if (response.status === 'ok') {
      toast.success('График работы обновлен');
      closeModal();
    } else {
      toast.error('При обновлении графика произошла ошибка');
    }
  };

  return (
    <Container>
      {state === PENDING && (
        <LoaderLayout>
          <CommonLoader />
        </LoaderLayout>
      )}
      <Wrapper>
        <Table>
          <Hours>
            {hours.map((item: any, index: number) => (
              <Slot key={`hour-${index}`}>{item}</Slot>
            ))}
          </Hours>
          <Side>
            {days.map((item: any, index) => (
              <div key={`day-${index}`}>
                <Slot>
                  <div
                    className='weekday-side'
                    style={{ display: 'flex', flexDirection: 'column', gap: '0' }}
                  >
                    <p>{formatDate(item.action)}</p>
                    <p>{item.title}</p>
                  </div>
                </Slot>
                {item.time.map((time: { title: string }) => (
                  <Slot
                    onClick={() => handleSlotClick(item.title, item.action, time.title)}
                    key={time.title}
                    className={dayjs(item.action + ' ' + time.title).diff(dayjs(), 'hour', true) < -1 ? 'disabled' : 'active'}
                  >
                    <>
                      {/* eslint-disable-next-line array-callback-return */}
                      {chosenEmployeeScheduleSlots.map((event: any, index) => {
                        const startTime = timeStringToNumber(event.time);
                        const endTime = timeStringToNumber(event.timeEnd);
                        const width = calculateEventWidth(startTime, endTime);

                        if (event.date === item.action && event.time === time.title) {
                          return (
                            <Event
                              //@ts-ignore
                              width={width}
                              key={index}
                              className='flex fill'
                            >
                              <span>{event.time}</span>
                              <span>-</span>
                              <span>{event.timeEnd}</span>
                            </Event>
                          );
                        }
                      })}
                    </>
                  </Slot>
                ))}
              </div>
            ))}
          </Side>
        </Table>
      </Wrapper>

      <Box>
        <BtnControlWrap>
          <CommonButton
            size={'M'}
            typeBtn={'gray'}
            fullWidth={false}
            onClick={() => closeModal()}
          >
            {t('Отменить')}
          </CommonButton>
          <CommonButton
            typeBtn='primary'
            fullWidth={false}
            type='button'
            size={'M'}
            onClick={() => saveSchedules(chosenEmployeeScheduleSlots)}
          >
            {t('Сохранить')}
          </CommonButton>
        </BtnControlWrap>
      </Box>
    </Container>
  );
};
export default EmployeeSchedule;
