import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { INavMenu } from './TopTabs.props';
import { CompanyLogo, LeftSection, NavMenuWrapper, Title, TopTabsWrapper } from './TopTabs.styled';

import TitleImg from '../../../../assets/images/title.png';
import { useFilialQuery } from '../../../../store/redux/filial/hooks/useFilialQuery';
import { getCookie } from '../../../../utils/cookies';
import { useActions } from '../../../lib/hooks/useActions';
import { Text, TextPType, TextType } from '../../HTag';

export const TopTabs: FC<INavMenu> = (props) => {
  const { tabs, title, children } = props;
  const { data: activeFilial } = useFilialQuery();

  const { t } = useTranslation();

  const { getMyTarifAsync, fetchUserByIdAsync, getMyBalanceAsync } = useActions();

  useEffect(() => {
    getMyTarifAsync();
    fetchUserByIdAsync(getCookie('id'));
    getMyBalanceAsync();
  }, []);

  return (
    <>
      <TopTabsWrapper
        $justify={'space-between'}
        $align={'center'}
      >
        {title && (
          <LeftSection
            $align={'center'}
            $justify={'flex-start'}
          >
            <Title
              $align={'center'}
              $gap={'16px'}
            >
              <CompanyLogo
                $align={'center'}
                $justify={'center'}
              >
                <img
                  src={activeFilial?.avatar || TitleImg}
                  alt={'title-img'}
                />
              </CompanyLogo>

              <Text
                $tag={TextType.H5}
                fontWeight={800}
              >
                {title}
              </Text>
            </Title>
            <NavMenuWrapper>
              {tabs
                ? tabs.map(({ title, to, disabled }) => (
                    <Text
                      key={title}
                      $tag={TextType.P}
                      $pSize={TextPType.P15}
                    >
                      <NavLink
                        to={to}
                        className={({ isActive, isPending }) =>
                          disabled ? (isActive ? 'flex active disabled' : 'flex disabled') : isActive ? 'flex active' : 'flex'
                        }
                      >
                        {t(title)}
                      </NavLink>
                    </Text>
                  ))
                : null}
            </NavMenuWrapper>
          </LeftSection>
        )}
        {children}
      </TopTabsWrapper>
    </>
  );
};
