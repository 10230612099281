import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useFormik } from 'formik';

import {
  CloseBtn,
  ConfirmDialog,
  DialogHeader,
  DialogWrapper,
  UserCardWrapper,
  UserItemContainer,
  UsetItemTextContainer,
  Wrapper
} from './UserListDialog.styled';

import { EIcon, IconNew as IconInstance, IconNew } from '../../../../components/icons/medium-new-icons/icon';
import NewButton from '../../../../shared/new-ui/NewButton/ui/NewButton';
import NewCustomCheckbox from '../../../../shared/new-ui/NewCustomCheckbox/ui/NewCustomCheckbox';
import { NewInput } from '../../../../shared/new-ui/NewInput';
import NewText from '../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType } from '../../../../shared/new-ui/NewText/ui/NewText.props';
import { Input, InputType, TextType } from '../../../../shared/ui';
import { useProfileUserQuery } from '../../../../store/redux/user/hooks/useUsersQuery';
import { IUser } from '../../../../store/redux/user/user.interface';
import { sendMessage } from '../../../../store/redux/websocket/chat-reducer';
import { MODAL_GAP } from '../../../../styles';
import { getCookie } from '../../../../utils/cookies';
import { FlexWithAlign, IconWrapper, Text2Xs } from '../../../../utils/styleUtils';
import { validationCreateChat } from '../../../../utils/validation-input';
interface IProps {
  onClose: () => void;
  userData: IUser[] | undefined;
  state?: string;
}

const UserListDialog: FC<IProps> = ({ onClose, userData, state }) => {
  const dialogRef = useRef<HTMLDivElement>(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const isButtonActive = selectedUsers.length > 0;
  const { data: profileUser } = useProfileUserQuery(getCookie('id'));
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialValues = {
    chatName: ''
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {},
    enableReinitialize: true,
    validationSchema: validationCreateChat,
    validateOnBlur: true
  });

  const handleCardClick = (user: IUser) => {
    if (user.id !== getCookie('id')) {
      const isSelected = selectedUsers.some((selectedUser) => selectedUser.id === user.id);

      if (isSelected) {
        setSelectedUsers((prevSelectedUsers) => prevSelectedUsers.filter((selectedUser) => selectedUser.id !== user.id));
      } else {
        setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, user]);
      }
    }
    setConfirmationOpen(false);
  };

  const handleCreateChat = () => {
    if (isButtonActive && formik.values.chatName) {
      const withUserId = selectedUsers.map((user) => ({ user_id: user.id }));

      const data = {
        type: 'create_chat',
        data: {
          chat_name: formik.values.chatName,
          created_by_user_id: getCookie('id'),
          with_user_id: withUserId
        }
      };
      dispatch(sendMessage(JSON.stringify(data)) as any);
      setConfirmationOpen(false);
      setSelectedUsers([]);
      onClose();
    }
  };

  return (
    <Wrapper ref={dialogRef}>
      <FlexWithAlign
        className='form-wrapper'
        $column
      >
        {state === 'rename' ? (
          <DialogWrapper>
            <DialogHeader
              $align={'center'}
              $gap={'10px'}
            >
              <IconWrapper className={'h25 main'}>
                <IconInstance name={EIcon.logomarkwhite} />
              </IconWrapper>
              {/*<PageTextLg className={'ultraDark'}>Изменение названия чата "{chatNameUpdate}"</PageTextLg>*/}
            </DialogHeader>
            <ConfirmDialog>
              <ul>
                {selectedUsers.map((user) => (
                  <li key={user.id}>
                    <IconWrapper className={'h22 medium-gray gray'}>
                      <IconInstance name={EIcon.userfilled} />
                    </IconWrapper>
                    <Text2Xs className={'medium'}>{user.fio}</Text2Xs>
                  </li>
                ))}
              </ul>
              <Input
                size={InputType.M}
                type='text'
                name={'chatName'}
                value={formik.values.chatName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.chatName && formik.errors.chatName}
              />
              <FlexWithAlign
                $justify={'flex-end'}
                className={'full'}
              >
                <NewButton onClick={onClose}>Отмена</NewButton>
                <NewButton typeBtn={'black'}>{t('Создать')}</NewButton>
              </FlexWithAlign>
            </ConfirmDialog>
          </DialogWrapper>
        ) : (
          <>
            <FlexWithAlign
              className='top-wrapper'
              $gap={'12px'}
              $align={'center'}
            >
              <>
                <CloseBtn
                  className='flex closebtn'
                  onClick={onClose}
                >
                  <IconNew name={EIcon.blackclose} />
                </CloseBtn>
                <NewText
                  $tag={TextType.H1}
                  fontWeight={600}
                  className='title'
                >
                  {isConfirmationOpen
                    ? selectedUsers.length === 1
                      ? t('Создание чата')
                      : t('Создание группового чата')
                    : t('Создание чата')}
                </NewText>
              </>
            </FlexWithAlign>
            <FlexWithAlign
              $column
              $gap={MODAL_GAP}
            >
              <UserCardWrapper
                $column
                $gap={'0px'}
              >
                <NewInput
                  placeholder='Введите название чата'
                  type='text'
                  autoFocus
                  name={'chatName'}
                  value={formik.values.chatName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.chatName && formik.errors.chatName}
                />
                <div className='names-container'>
                  <UserItemContainer>
                    <NewCustomCheckbox
                      key={profileUser?.id}
                      name={profileUser ? profileUser?.fio : ''}
                      value={true}
                      disabled={true}
                      onChange={() => {}}
                    />
                    <UsetItemTextContainer>
                      <FlexWithAlign
                        className={'messenger-modal'}
                        $position={'flex'}
                        $column={false}
                        $justify={'space-between'}
                      >
                        <NewText
                          $customSize={TextCustomType.T14}
                          $tag={TextType.P}
                          fontWeight={400}
                          color={'title'}
                        >
                          {profileUser?.fio}
                        </NewText>
                        <NewText
                          $customSize={TextCustomType.T14}
                          $tag={TextType.P}
                          fontWeight={400}
                          color={'grey'}
                        >
                          {profileUser?.Email}
                        </NewText>
                      </FlexWithAlign>
                    </UsetItemTextContainer>
                  </UserItemContainer>
                  {userData &&
                    userData
                      .filter((user) => user.id !== getCookie('id'))
                      .map((user) => (
                        <UserItemContainer key={user.id}>
                          <NewCustomCheckbox
                            key={user.id}
                            value={selectedUsers.some((selectedUser) => selectedUser.id === user.id)}
                            name={user.id}
                            onChange={() => handleCardClick(user)}
                          />
                          <UsetItemTextContainer>
                            <FlexWithAlign
                              className={'messenger-modal'}
                              $position={'flex'}
                              $column={false}
                              $justify={'space-between'}
                            >
                              <NewText
                                $customSize={TextCustomType.T14}
                                $tag={TextType.P}
                                fontWeight={400}
                                color={'title'}
                              >
                                {user.fio}
                              </NewText>

                              <NewText
                                $customSize={TextCustomType.T14}
                                $tag={TextType.P}
                                fontWeight={400}
                                color={'grey'}
                              >
                                {user.Email}
                              </NewText>
                            </FlexWithAlign>
                          </UsetItemTextContainer>
                        </UserItemContainer>
                      ))}
                </div>
              </UserCardWrapper>
              <FlexWithAlign
                $justify={'flex-end'}
                className={`messenger-modal-button`}
              >
                <NewButton onClick={onClose}>ОТМЕНА</NewButton>
                <NewButton
                  typeBtn={'black'}
                  fullWidth={false}
                  disabled={!isButtonActive || formik.values.chatName === ''}
                  onClick={handleCreateChat}
                >
                  СОЗДАТЬ
                </NewButton>
              </FlexWithAlign>
            </FlexWithAlign>
          </>
        )}
      </FlexWithAlign>
    </Wrapper>
  );
};

export default UserListDialog;
