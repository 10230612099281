export const isLinkActive = (to: string, pathname: string) => {
  const toPath = to.split('/')[1];
  const locationPath = pathname.split('/')[1];

  if (toPath === 'sales') {
    return locationPath === 'sales' || locationPath === 'payments';
  }
  if (toPath === 'employees') {
    return locationPath === 'employees' || locationPath === 'timetable' || locationPath === 'cabinets';
  }
  if (toPath === 'calendar') {
    return locationPath === 'calendar' || locationPath === 'list';
  }
  if (toPath === 'settings') {
    return (
      locationPath === 'payments-and-sales' ||
      locationPath === 'settings' ||
      locationPath === 'cash-registers' ||
      locationPath === 'cash-register' ||
      locationPath === 'payments-settings' ||
      locationPath === 'transactions-all' ||
      locationPath === 'business-settings' ||
      locationPath === 'service-category' ||
      locationPath === 'service' ||
      locationPath === 'subscribe' ||
      locationPath === 'personal-area' ||
      locationPath === 'online-recording' ||
      locationPath === 'telegram-bot-info' ||
      locationPath === 'personal-site' ||
      locationPath === 'portal' ||
      locationPath === 'licence' ||
      locationPath === 'user-agreement' ||
      locationPath === 'privacy-policy'
    );
  }

  return toPath === locationPath;
};
