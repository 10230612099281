import styled from 'styled-components';
import { FlexWithAlign } from '../../../../utils/styleUtils';
import { RADIUS_M, SHADOW } from '../../../../styles';

export const TopTabsWrapper = styled(FlexWithAlign)`
  padding: 14px 0 10px 0;
  margin: 0 auto 24px;
  max-width: calc(100% - 80px);
  width: 100%;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background: ${(props) => props.theme.color.lightBg};
  }
`;

export const CompanyLogo = styled(FlexWithAlign)`
  width: 40px;
  height: 40px;
  border-radius: ${RADIUS_M};
  overflow: hidden;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
export const LeftSection = styled(FlexWithAlign)``;

export const Title = styled(FlexWithAlign)`
  padding: 0 5px;
`;

export const NavMenuWrapper = styled(FlexWithAlign)`
  z-index: 1;

  a,
  button {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    border-radius: 8px;
    transition: all 0.4s ease;
    color: ${(props) => props.theme.text.text03};

    &::after {
      content: '';
      position: absolute;

      border-radius: 1px 1px 0 0;
      left: 0;
      bottom: -20px;
      width: 100%;
      height: 2px;
      visibility: hidden;
      transition: all 0.4s ease;
    }
    &.active::after {
      background-color: ${(props) => props.theme.color.primary};
      visibility: visible;
      bottom: -17px;
    }
    &:hover,
    &.active {
      opacity: 1;
      color: ${(props) => props.theme.text.text01};
    }
    &.disabled {
      pointer-events: none;
    }
  }
`;

export const MobileMenu = styled(FlexWithAlign)`
  position: relative;
  width: 100%;

  height: 100%;
  gap: 12px;
  inset: 0;
  background: ${(props) => props.theme.color.base01};
  padding: 0 1rem 0 1rem;
`;

export const MobileLinkItem = styled(FlexWithAlign)`
  padding: 0.9rem 1.25rem;
  align-items: center;
  height: 80px;
  justify-content: space-between;
  gap: 1.25rem;
  box-shadow: ${SHADOW};
  border-radius: ${RADIUS_M};
  position: relative;
  > a {
    position: absolute;
    inset: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
  }
  //.circle{
  //  svg{
  //    width: 30px;
  //    height: 30px;
  //  }
  //}
`;
export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  height: 44px;
  gap: 1rem;
  // &:before {
  //   content: '';
  //   position: absolute;
  //   bottom: 0;
  //   left: -1rem;
  //   width: calc(100% + 2rem);
  //   height: 1px;
  //   background: ${(props) => props.theme.color.base04};
  // }
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: ${(props) => props.theme.text.text01};
    }
  }
  &.modal {
    justify-content: space-between;
    width: 100%;
  }
`;
