import styled from 'styled-components';
import { MODAL_GAP } from '../../../../../styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${MODAL_GAP};
  height: 100%;
`;

export const Topbar = styled.div`
  padding: 29px 58px 13px 56px;
  border: 2px solid ${(props) => props.theme.color.base03};
`;

export const TitleModal = styled.h2`
  color: ${(props) => props.theme.color.secondaryDark};
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const EmployeeInfo = styled.div`
  padding: 23px 41px;
  justify-content: flex-start;
  gap: 16px;
`;

export const Avatar = styled.div`
  width: 80px;
  height: 80px;
  background-color: ${(props) => props.theme.color.mainLight};
  color: ${(props) => props.theme.color.base01};
  justify-content: center;
  text-align: center;
  font-size: 28px;
`;

export const NavWrap = styled.ul`
  display: flex;
  gap: 30px;
  border-bottom: 1px solid ${(props) => props.theme.color.base04};
`;

export const NavItem = styled.li`
  button {
    padding-bottom: 8px;
    position: relative;
    font-weight: 600;
    color: ${(props) => props.theme.color.secondaryDark};
    &:hover,
    &.active {
      color: ${(props) => props.theme.color.mainDark};
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: transparent;
    }
    &:hover::after,
    &:focus::after,
    &.active::after {
      background-color: #246bfd;
    }
  }
`;

export const TopDateBox = styled.div`
  padding: 12px 10px;
  .date {
    gap: 12px;
    align-items: center;
    > svg {
      color: ${(props) => props.theme.text.text02};
    }
  }
  &.finance {
    padding: 0;
  }
`;

export const DateIntervalWrap = styled.div`
  font-weight: 600;
`;

export const Content = styled.div`
  display: flex;
  gap: 8px;
  .field {
    width: 100%;
  }
`;
