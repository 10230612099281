import { NavLink } from 'react-router-dom';

import styled from 'styled-components';

import { DURATION } from '../../../../styles';
import { FlexWithAlign } from '../../../../utils/styleUtils';
import { scaleIn } from '../../../shared/modal/create/service/sidebar/CreateServiceSidebar.styled';

export const SidebarContainer = styled.div`
  z-index: 5;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-width: 207px;
  max-width: 207px;
  grid-area: sidebar;
  position: sticky;
  top: 0;
  background: ${(props) => props.theme.newColor.black03};
  height: 100vh;
  padding: 16px 12px;
  transition: 0.3s ease;
  &.with-expire-tarif {
    height: fit-content;
    min-height: calc(100vh - 50px);
  }
  &.collapsed {
    min-width: 56px;
    max-width: 56px;
    padding: 16px 0;
  }
  &:not(.collapsed) {
    .profile-avatar {
      transform: translateX(16px);
    }
  }
  @media (max-width: 1024px) {
    max-width: 175px;
    min-width: 175px;
  }
`;

export const SidebarTop = styled(FlexWithAlign)`
  margin: 0 18px 8px 18px;
  padding-bottom: 4px;
  border-bottom: 1px solid ${(props) => props.theme.newColor.grey06};
  animation: ${scaleIn} 0.25s forwards ease-in-out;
  transform-origin: top;
`;

export const MenuSubMenu = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1500px) {
    gap: 0;
  }
`;

export const EndTab = styled.div`
  background: ${(props) => props.theme.newColor.grey06};
  width: calc(100% - 30px);
  margin: 8px 14px;
  height: 1px;
`;

export const MenuItem = styled(FlexWithAlign)`
  max-width: calc(100% - 14px);
  position: relative;
  margin: 8px 0 8px 14px;
  width: 100%;
  .message-unread {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    right: 10px;
    position: absolute;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.newColor.base05};
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    animation: ${scaleIn} 0.3s ease;
    z-index: -1;
    left: -11px;
    width: 3px;
    height: 100%;
    transition: 0.3s ease;
  }
  p {
    color: ${(props) => props.theme.newColor.grey03};
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    width: min-content;
    animation: ${scaleIn} 0.25s forwards ease-in-out;
    transform-origin: left;
  }
  &:hover {
    p,
    svg {
      color: ${(props) => props.theme.newColor.base05};
    }
  }
  &.active {
    p {
      color: ${(props) => props.theme.newColor.base05};
    }
    &:before {
      background: ${(props) => props.theme.newColor.base05};
    }
  }
`;

export const UnreadMessages = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.newColor.base05};
  position: absolute;
  top: -2px;
  left: 18px;
`;

export const MenuLink = styled(NavLink)`
  font-weight: 500;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 28px;
  height: 28px;
  color: ${(props) => props.theme.newColor.grey03};
  &.active {
    svg {
      color: ${(props) => props.theme.newColor.base05};
    }
  }
`;

export const CollapsedButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: ${(props) => props.theme.newColor.base05};
  }
`;

export const SideTooltip = styled.div`
  color: ${(props) => props.theme.newColor.base01};
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  padding: 1px 7px;
  border-radius: 999px;
  position: absolute;
  background: ${(props) => props.theme.newColor.grey07};
  left: 50px;
  width: max-content;
  visibility: hidden;
  &.active {
    visibility: visible;
    animation: ${scaleIn} ${DURATION} forwards ease-in-out;
    transform-origin: left;
  }
`;

export const ProfileUserSide = styled.div`
  // position: relative;
  position: fixed;
  left: 0;
  bottom: 16px;
  width: min-content;
  margin: 0 10px;
  transition: transform 0.3s ease;
  > a {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const SideAbsoluteCircle = styled.div`
  position: absolute;
  bottom: -3px;
  right: 0;
`;
