import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { Box, ButtonsWrap, CloseBtn, DeleteBtn, Wrapper } from './CreateServiceCategoryModal.styled';

import { useActions } from '../../../../../../shared/lib/hooks/useActions';
import NewButton from '../../../../../../shared/new-ui/NewButton/ui/NewButton';
import { NewInput } from '../../../../../../shared/new-ui/NewInput';
import NewText from '../../../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType, TextType } from '../../../../../../shared/new-ui/NewText/ui/NewText.props';
import { ModalSize, ModalType } from '../../../../../../shared/ui';
import { useCreateProduct, useUpdateProduct } from '../../../../../../store/redux/products/hooks/useProductsMutation';
import { useProductsQuery } from '../../../../../../store/redux/products/hooks/useProductsQuery';
import { IProduct } from '../../../../../../store/redux/products/products.interface';
import { FlexWithAlign, FormStyle } from '../../../../../../utils/styleUtils';
import { EIcon, IconNew as IconInstance } from '../../../../../icons/medium-new-icons/icon';

interface IProps {
  modalPayload?: any;
  edit?: boolean;
  closeModal: () => void;
}

const CreateServiceCategoryModal: React.FC<IProps> = (props) => {
  const { modalPayload: serviceCategory, edit, closeModal } = props;
  const { data: products } = useProductsQuery();
  const { mutateAsync: createProduct, isPending } = useCreateProduct();
  const { openModal } = useActions();
  const { mutateAsync: updateProduct } = useUpdateProduct();

  const { t } = useTranslation();

  const [nameError, setNameError] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const productEdit = products?.filter((item) => item.id === serviceCategory?.id)[0];

  const initialValues = {
    id: '',
    name: '',
    comment: ''
  };

  const formik = useFormik({
    initialValues: productEdit
      ? {
          id: productEdit.id,
          name: productEdit.name
        }
      : initialValues,
    onSubmit: (values: any) => {}
  });

  useEffect(() => {
    if (formik.values.name) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formik.values]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (edit) {
        const res = await updateProduct(formik.values);
        if (res.status === 'ok') {
          toast.success(t('Категория успешно обновлена'));
        }
      } else {
        const res = await createProduct(formik.values);
        if (res.status === 'ok') {
          toast.success(t('Категория успешно Добавлена'));
        }
      }
      closeModal?.();
    } catch (error: any) {
      toast.error(error.message || t('Произошла ошибка'));
    }
  };

  useEffect(() => {
    if (nameError) {
      formik.values.name !== '' && setNameError(false);
    }
  }, [formik.values.name]);

  const handleDeleteCategory = (serviceCategory: IProduct) => {
    openModal({
      modalName: 'CONFIRM_NEW_DELETE',
      modalSize: ModalSize.CONFIRM_DELETE,
      modalPayload: serviceCategory,
      modalType: ModalType.TOP,
      actionName: 'PRODUCT',
      classModal: 'danger'
    });
  };

  return (
    <Wrapper>
      <FormStyle onSubmit={handleSubmit}>
        <FlexWithAlign $gap='16px'>
          <CloseBtn
            typeBtn={'close'}
            onClick={closeModal}
          >
            <IconInstance name={EIcon.close} />
          </CloseBtn>
          <NewText
            $tag={TextType.H2}
            fontWeight={600}
            color='title'
          >
            {edit ? `${t('Редактирование')}` : t('Новая категория')}
          </NewText>
        </FlexWithAlign>

        <Box className='form'>
          <NewInput
            label={t('Название категории')}
            value={formik.values.name}
            onChange={formik.handleChange}
            name='name'
            error={nameError && 'Введите название'}
          />
          <div>
            <NewText
              $tag={TextType.P}
              $customSize={TextCustomType.T12}
              color='grey'
            >
              {'Создавайте неограниченное количество категорий, чтобы удобно группировать и разделять услуги компании'}
            </NewText>
          </div>
          <ButtonsWrap
            $align='center'
            $gap={'16px'}
          >
            {edit ? (
              <DeleteBtn
                type='button'
                typeBtn='without-border'
                onClick={() => handleDeleteCategory(serviceCategory)}
              >
                <IconInstance name={EIcon.deletebreak} />
              </DeleteBtn>
            ) : null}
            <FlexWithAlign
              $align='center'
              $gap='16px'
              style={{ marginLeft: 'auto' }}
            >
              <NewButton
                onClick={closeModal}
                fullWidth={false}
                type='button'
              >
                {t('Отмена')}
              </NewButton>
              <NewButton
                typeBtn='black'
                disabled={!formValid || isPending}
                type='submit'
              >
                {t('Сохранить')}
              </NewButton>
            </FlexWithAlign>
          </ButtonsWrap>
        </Box>
      </FormStyle>
    </Wrapper>
  );
};

export default CreateServiceCategoryModal;
