import { ChangeEvent, FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import dayjs from 'dayjs';
import { useFormik } from 'formik';

import { BreakLabel, CloseBtn, DurationWrap, Wrapper } from './CreateTimeTable.styled';

import { ModalGrid } from '../../../../../pages/RecordingPage/lib/CommonRecording.styled';
import { useActions } from '../../../../../shared/lib/hooks/useActions';
import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';
import NewButton from '../../../../../shared/new-ui/NewButton/ui/NewButton';
import NewText from '../../../../../shared/new-ui/NewText/ui/NewText';
import Timepicker from '../../../../../shared/new-ui/NewTimePicker/ui/NewTimepicker';
import ToggleButton from '../../../../../shared/new-ui/NewToggleButton/ui/NewToggleButton';
import { InputType } from '../../../../../shared/ui';
import { useAllUsersScheduleQuery, useUsersQuery } from '../../../../../store/redux/user/hooks/useUsersQuery';
import { IUserGroupedEmployeeSchedules } from '../../../../../store/redux/user/user.interface';
import { MODAL_GAP, MODAL_TEXT_GAP } from '../../../../../styles';
import { apiPut } from '../../../../../utils/apiInstance';
import { generateRandomString, getTimeDuration, getTimeDurationStr, transformStringCountDays } from '../../../../../utils/helperFunctions';
import { FlexWithAlign, FormStyle } from '../../../../../utils/styleUtils';
import { IconNew, EIcon } from '../../../../icons/medium-new-icons/icon';
import { TextCustomType } from '../../../../../shared/new-ui/NewText/ui/NewText.props';

interface IProps {
  closeModal: () => void;
  edit?: boolean;
  modalPayload?: any;
  closeOnOverlayClick?: boolean;
}

const CreateTimeTable: FC<IProps> = (props) => {
  const { closeModal, modalPayload } = props;
  const { setActiveDate, updateTimeTableArr, clearTimeTableArr } = useActions();
  const { chosenMonth, chosenWeek, currViewTimetable } = useTypedSelector((state1) => state1.calendar);
  const { timeTableArr, showModal } = useTypedSelector((state) => state.modal);
  const { data: user } = useUsersQuery();
  const { data: userSchedules, refetch: updateTimeTable } = useAllUsersScheduleQuery({
    ids: user?.map((item) => item.id),
    dateStart: currViewTimetable.value === 'month' ? chosenMonth[0] : chosenWeek[0],
    dateEnd: currViewTimetable.value === 'month' ? chosenMonth[chosenMonth.length - 1] : chosenWeek[chosenWeek.length - 1]
  });
  const chosenSchedule = userSchedules?.find(
    (schedule) =>
      schedule?.employeeId === timeTableArr[0]?.employeeId && schedule?.date === dayjs(timeTableArr[0]?.date).format('YYYY-MM-DD')
  );
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [formValid, setFormValid] = useState(false);
  const [isNonWorking, setIsNonWorking] = useState<boolean>(false);
  const [isBreakShow, setIsBreakShow] = useState<boolean>(false);
  const initialValues = {
    timestart: '',
    timeend: '',

    breaks: [{ bstart: '', bend: '' }]
  };
  const formik = useFormik({
    initialValues:
      timeTableArr && timeTableArr.length === 1
        ? {
            timestart: chosenSchedule ? chosenSchedule?.timestart : '',
            timeend: chosenSchedule ? chosenSchedule?.timeend : '',
            breaks: chosenSchedule && chosenSchedule.breaks ? chosenSchedule?.breaks : [{ bstart: '', bend: '' }]
          }
        : initialValues,
    onSubmit: (values: any) => {}
  });
  useEffect(() => {
    if (isNonWorking) {
      setFormValid(true);
    } else {
      if (
        formik.values.timestart.length === 5 &&
        formik.values.timeend.length === 5 &&
        formik.values.timestart !== '' &&
        formik.values.timeend !== '' &&
        formik.values.timestart <= formik.values.timeend
      ) {
        setFormValid(true);
      } else {
        setFormValid(false);
      }
      // if (formik.values.timestart > formik.values.timeend) {
      //   formik.setFieldValue('timeend', '');
      // }
    }
  }, [formik.values, isNonWorking]);

  const updateEmployeeSchedule = async (values: any) => {
    if (timeTableArr && timeTableArr.length > 0) {
      const resultArr = Object.values(
        timeTableArr.reduce<Record<string, IUserGroupedEmployeeSchedules>>((acc, item) => {
          const { employeeId, ...rest } = item;

          if (!acc[employeeId]) {
            acc[employeeId] = { employeeId, dates: [] };
          }
          acc[employeeId].dates.push(rest);

          return acc;
        }, {})
      );
      const promises = resultArr.map((item) =>
        apiPut(
          `/scheduser/${item.employeeId}`,
          item.dates.map((itemDate) => {
            return {
              date: dayjs(itemDate.date).format('YYYY-MM-DD'),
              timeend: isNonWorking ? '' : values.timeend,
              timestart: isNonWorking ? '' : values.timestart,
              breaks: isNonWorking
                ? null
                : values.breaks && values.breaks.filter((item: any) => item.bstart !== '' && item.bend !== '').length > 0
                ? values.breaks.filter((item: any) => item.bstart !== '' && item.bend !== '')
                : null
            };
          })
        )
      );
      const valuesData = await Promise.all(promises);

      if (valuesData.length > 0) {
        if (valuesData.every((item) => item.status === 200)) {
          toast.success('График обновлен');
          closeModal!();
          setActiveDate(timeTableArr[0].date);
          updateTimeTable();
        } else {
          toast.error(valuesData.find((item) => item.status !== 200)?.data.description || 'Ошибка');
        }
      }

      clearTimeTableArr();
    } else {
      toast('Выберите хотя бы 1 день');
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    updateEmployeeSchedule(formik.values);
  };

  // useEffect(() => {
  //   if (modalPayload) {
  //     updateTimeTableArr({ employeeId: modalPayload.employeeId, date: modalPayload.date });
  //   }
  // }, []);

  const addBreakInput = () => {
    if (formik.values && formik.values.breaks.length >= 3) return;
    setIsBreakShow(true);
    const currBreaks = formik.values.breaks;
    if (currBreaks.length === 1 && currBreaks[0].bstart === '' && !isBreakShow) {
      formik.setValues({
        ...formik.values,
        breaks: [{ bstart: '', bend: '' }]
      });
    } else {
      formik.setValues({
        ...formik.values,
        breaks: [...formik.values.breaks, { bstart: '', bend: '' }]
      });
    }
  };
  const handleInputChange = (index: number, e: ChangeEvent<HTMLInputElement>, name: string) => {
    const newInputs = [...formik.values.breaks];

    if (name === 'bstart') {
      newInputs[index] = { ...newInputs[index], bstart: e.target?.value };
    } else if (name === 'bend') {
      newInputs[index] = { ...newInputs[index], bend: e.target?.value };
    }
    formik.setFieldValue('breaks', newInputs);
  };

  const deleteBreakInput = (index: number) => {
    const filterBreaks = formik.values.breaks.filter((item: any, i: number) => index !== i);
    formik.setValues({
      ...formik.values,
      breaks: [...filterBreaks]
    });
    if (filterBreaks.length === 0) {
      setIsBreakShow(false);
    }
  };

  useEffect(() => {
    if (!pathname.includes('/timetable') && showModal) {
      closeModal!();
    }
  }, [pathname]);
  const duration = getTimeDuration(formik.values.timestart, formik.values.timeend);

  return (
    <Wrapper>
      <FlexWithAlign
        $gap={MODAL_GAP}
        $column
      >
        <FlexWithAlign
          className='top-wrapper'
          $gap={'12px'}
          $align={'center'}
        >
          <CloseBtn
            className='flex'
            onClick={() => {
              clearTimeTableArr();
              closeModal();
            }}
          >
            <IconNew name={EIcon.close} />
          </CloseBtn>
          <NewText
            fontWeight={700}
            color='title'
            $customSize={TextCustomType.T28}
          >
            {t('Изменение графика • ')}
          </NewText>
          <span className='count-days'>
            {timeTableArr.length} {t(transformStringCountDays(timeTableArr.length))}
          </span>
        </FlexWithAlign>
        <FlexWithAlign
          $column
          $gap={MODAL_TEXT_GAP}
          className={'form-wrapper'}
        >
          <FormStyle onSubmit={handleSubmit}>
            <FlexWithAlign
              $column
              $gap='20px'
              className='form-content'
            >
              <FlexWithAlign $gap='12px'>
                <ToggleButton
                  isActive={!isNonWorking}
                  onClick={() => setIsNonWorking(!isNonWorking)}
                  className='is-working-btn'
                >
                  <span>{t('Рабочий день')}</span>
                </ToggleButton>
                <ModalGrid className='input-wrap'>
                  <Timepicker
                    size={InputType.M}
                    value={formik.values.timestart}
                    name={'timestart'}
                    onChange={formik.handleChange}
                    className='time-table-input'
                  />
                  <Timepicker
                    size={InputType.M}
                    value={formik.values.timeend}
                    name={'timeend'}
                    onChange={formik.handleChange}
                    lowerLimit={formik.values.timestart}
                    className='time-table-input'
                  />
                  <DurationWrap
                    $gap='4px'
                    $align='center'
                  >
                    <IconNew name={EIcon.change} />
                    {formik.values.timestart && formik.values.timeend && (
                      <span>
                        {duration ? (duration.hours === 0 ? `${duration.minutes}м` : `${duration.hours}ч ${duration.minutes}м`) : ''}
                      </span>
                    )}
                  </DurationWrap>
                </ModalGrid>
                <NewButton
                  type='button'
                  typeBtn='without-border'
                  onClick={addBreakInput}
                  disabled={formik.values.breaks.length >= 3}
                  classNameWrapper={'add-break-btn'}
                >
                  <IconNew name={EIcon.addbreak} />
                  <span>{t('Добавить перерыв')}</span>
                </NewButton>
              </FlexWithAlign>

              {(isBreakShow || (chosenSchedule && chosenSchedule?.breaks)) && formik.values.breaks && formik.values.breaks.length > 0 ? (
                <>
                  <FlexWithAlign
                    $gap='12px'
                    $column
                  >
                    {formik.values.breaks.map((input: any, i: number) => (
                      <FlexWithAlign
                        $gap='12px'
                        key={generateRandomString()}
                        $align='center'
                      >
                        <BreakLabel className='break-label'>{t('Перерыв')}</BreakLabel>
                        <ModalGrid className='break-wrap'>
                          <Timepicker
                            size={InputType.M}
                            // label={t('Начало')}
                            value={formik.values.breaks.find((item: any, itemIndex: number) => itemIndex === i)?.bstart || input.bstart}
                            name={`bstart-${i}`}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(i, e, 'bstart')}
                            lowerLimit={formik.values.timestart}
                            upperLimit={input.bend || formik.values.timeend}
                            disabled={!formik.values.timeend || !formik.values.timestart}
                            isBreak={true}
                            className='time-table-input'
                          />
                          <Timepicker
                            size={InputType.M}
                            // label={t('Конец')}
                            value={formik.values.breaks.find((item: any, itemIndex: number) => itemIndex === i)?.bend || input.bend}
                            name={`bend-${i}`}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(i, e, 'bend')}
                            lowerLimit={input.bstart || formik.values.timestart}
                            upperLimit={formik.values.timeend}
                            disabled={!formik.values.timeend || !formik.values.timestart}
                            isBreak={true}
                            className='time-table-input'
                          />
                          <FlexWithAlign
                            $gap='2px'
                            $align='center'
                          >
                            <DurationWrap
                              $gap='4px'
                              $align='center'
                            >
                              <IconNew name={EIcon.change} />
                              {formik.values.breaks &&
                                formik.values.breaks.find((item: any, itemIndex: number) => itemIndex === i)?.bstart &&
                                formik.values.breaks.find((item: any, itemIndex: number) => itemIndex === i)?.bend && (
                                  <span>
                                    {`${getTimeDurationStr(
                                      formik.values.breaks.find((item: any, itemIndex: number) => itemIndex === i)?.bstart,
                                      formik.values.breaks.find((item: any, itemIndex: number) => itemIndex === i)?.bend,
                                      true
                                    )}`}
                                  </span>
                                )}
                            </DurationWrap>
                            <button
                              type='button'
                              className='delete-break-btn'
                              onClick={(e) => {
                                e.preventDefault();

                                deleteBreakInput(i);
                              }}
                            >
                              <IconNew name={EIcon.deletebreak} />
                            </button>
                          </FlexWithAlign>
                        </ModalGrid>
                      </FlexWithAlign>
                    ))}
                  </FlexWithAlign>
                </>
              ) : null}
            </FlexWithAlign>

            <FlexWithAlign
              $justify='flex-end'
              $gap='16px'
              className='btns-wrap'
            >
              <NewButton
                onClick={() => {
                  clearTimeTableArr();
                  closeModal();
                }}
              >
                <span>{t('Отмена')}</span>
              </NewButton>
              <NewButton
                type='submit'
                disabled={!formValid || !timeTableArr || timeTableArr.length === 0}
                typeBtn='black'
              >
                <span>{t('Сохранить')}</span>
              </NewButton>
            </FlexWithAlign>
          </FormStyle>
        </FlexWithAlign>
      </FlexWithAlign>
    </Wrapper>
  );
};

export default CreateTimeTable;
