import styled from 'styled-components';
import {
  DURATION,
  HEIGHT_INPUT_M,
  SHADOW_INPUT_HOGER,
  RADIUS_M,
  SHADOW_INPUT,
  MODAL_TEXT_GAP,
  FONT_M,
  RADIUS_S
} from '../../../../../../styles';
import { FlexWithAlign } from '../../../../../../utils/styleUtils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .form-wrapper {
    margin: 0;
    max-width: 700px;
  }
`;
export const Box = styled.div`
  width: 100%;

  &.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .inviteemployeer {
    position: relative;
    width: fit-content;
    display: flex;
    height: ${HEIGHT_INPUT_M};
    align-items: center;
    gap: ${MODAL_TEXT_GAP};

    &-input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
      &:checked + h5 {
        color: ${(props) => props.theme.color.base01};
      }
      &:hover + button {
        border-color: ${(props) => props.theme.color.primary};
      }
      &:disabled + button {
        opacity: 0.5;
      }
    }
    .custom-check {
      width: 24px;
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 24px;
      background: transparent;
      border-radius: ${RADIUS_S};
      transition: background ${DURATION} ease-in-out;
      &:after {
        transition-property: border-color, color;
        transition-duration: ${DURATION};
        transition-timing-function: ease-in-out;
        position: absolute;
        pointer-events: none;
        inset: 0;
        content: '';
        border-radius: inherit;
        border: 1px solid currentColor;
        color: ${(props) => props.theme.color.base04};
      }
      svg {
        color: #fff;
        transition: scale ${DURATION} ease-in-out;
        transform: scale(0);
      }
      &.active {
        background: ${(props) => props.theme.text.link};
        &:after {
          border-color: ${(props) => props.theme.color.primary};
        }
        svg {
          transform: scale(1);
        }
      }
    }
    .icon-wrap {
      position: relative;
      color: ${(props) => props.theme.color.gray66};
    }
  }
`;
export const UploadBlock = styled(FlexWithAlign)`
  max-width: 70%;
  width: fit-content;
`;

export const InputUpload = styled.div`
  position: relative;
  min-height: ${HEIGHT_INPUT_M};
  font: ${FONT_M};
  border-radius: ${RADIUS_M};
  background: transparent;
  word-wrap: break-word;
  color: ${(props) => props.theme.text.text02};
  //padding: 0 0.5rem;
  padding: 0;
  box-sizing: border-box;
  transition-timing-function: ease-in-out;
  transition-duration: ${DURATION};
  transition-property: color, background, opacity;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //cursor: pointer;
  // &:hover {
  //   background: ${(props) => props.theme.color.secondary};
  // }
  p {
    span {
      color: ${(props) => props.theme.text.link};
    }
  }
  input {
    position: absolute;
    inset: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
  label {
    min-height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: ${(props) => props.theme.text.text02};
    word-wrap: break-word;
  }
  // &:after {
  //   border: 1px dashed;
  //   color: ${(props) => props.theme.text.link};
  //   transition-property: box-shadow, color;
  //   transition-duration: ${DURATION};
  //   transition-timing-function: ease-in-out;
  //   position: absolute;
  //   inset: 0;
  //   content: '';
  //   border-radius: inherit;
  // }
`;

export const ButtonInner = styled.div`
  display: flex;
  gap: 12px;
`;
export const CustomCheckboxWrap = styled.button`
  display: flex;
  gap: ${MODAL_TEXT_GAP};
  box-shadow: ${SHADOW_INPUT};
  border-radius: ${RADIUS_M};
  height: ${HEIGHT_INPUT_M};
  width: 100%;
  padding-inline: 1rem;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  &:disabled {
    background: ${(props) => props.theme.color.secondary};
  }
  &:after {
    transition-property: color;
    transition-duration: ${DURATION};
    transition-timing-function: ease-in-out;
    color: ${(props) => props.theme.color.base03};
    position: absolute;
    inset: 0;
    content: '';
    border-radius: inherit;
    border: 1px solid currentColor;
    pointer-events: none;
  }
  &:hover {
    box-shadow: ${SHADOW_INPUT_HOGER};
  }
  .check-wrapper {
    position: relative;
    height: 22px;
    overflow: hidden;
    width: 22px;
    border-radius: 50%;
    transition: background-color 0.3s;
    z-index: 1;

    &:after {
      transition-property: color;
      transition-duration: ${DURATION};
      transition-timing-function: ease-in-out;
      color: ${(props) => props.theme.color.base03};
      position: absolute;
      inset: 0;
      content: '';
      border-radius: inherit;
      border: 2px solid currentColor;
      pointer-events: none;
      transition: scale ${DURATION} ease-in-out;
    }
    span {
      transform: scale(0);
      transition: scale ${DURATION} ease-in-out;
    }
    &.active {
      &:after {
        transform: scale(0);
      }
      span {
        transform: scale(1);
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${(props) => props.theme.text.link};
        &:after {
          content: '';
          background: ${(props) => props.theme.color.base01};
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }
      }
    }
    .checkmark {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }
  }
  &.sheet {
    padding-inline: 0;
    padding-block: 2rem;
    border-radius: 0;
    box-shadow: none;
    &:after {
      content: none;
    }
    &:hover {
      background: transparent;
    }
  }
`;
