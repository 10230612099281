import { darken } from 'polished';
import styled from 'styled-components';

import { DURATION } from '../../../../styles';
import { FlexWithAlign, scaleIn, scaleOut } from '../../../../utils/styleUtils';

interface IButtonProps {
  $color: string;
  $border?: string;
  $fullWidth?: boolean;
  $padding?: string;
  $gap?: string;
  $justifyContent?: string;
  $position?: string;
  $typeBtn?: 'gray' | 'primary' | 'secondary' | 'flat' | 'success' | 'danger' | 'accent' | 'outline' | 'black' | 'white';
  $appearance?: boolean;
  $size?: 'L' | 'M' | 'S' | 'XS';
  $onlyIcon?: boolean;
}

export const Button = styled.button<IButtonProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: ${(props) => (props.$border ? props.$border : 'none')};
  outline: none;
  transition: background ${DURATION} ease-in-out;
  position: ${(props) => (props.$position ? props.$position : 'static')};
  justify-content: ${(props) => (props.$justifyContent ? props.$justifyContent : 'center')};
  padding: ${(props) => {
    if (props.$padding) {
      return props.$padding;
    }
    if (props.$onlyIcon && props.$size !== undefined) {
      switch ((props.$onlyIcon, props.$size)) {
        case 'L':
          return props.$size ? '16px' : '5px';
        case 'M':
          return props.$size ? '10px' : '5px ';
        case 'S':
          return props.$size ? '6px' : '5px ';
        case 'XS':
          return props.$size ? '2px' : '5px ';

        default:
          return '5px';
      }
    } else if (props.$size !== undefined) {
      switch (props.$size) {
        case 'L':
          return props.$size ? '16px 24px' : '5px 12px';
        case 'M':
          return props.$size ? '10px 18px' : '5px 12px';
        case 'S':
          return props.$size ? '6px 12px' : '5px 12px';
        case 'XS':
          return props.$size ? '2px 8px' : '5px 12px';
        default:
          return '5px 12px';
      }
    } else {
      return '5px 12px';
    }
  }};
  line-height: ${(props) => {
    if (props.$size !== undefined) {
      switch (props.$size) {
        case 'L':
          return props.$size ? '24px' : '24px';
        case 'M':
          return props.$size ? '24px' : '24px';
        case 'S':
          return props.$size ? '20px' : '24px';
        case 'XS':
          return props.$size ? '18px' : '24px';
        default:
          return '24px';
      }
    } else {
      return '24px';
    }
  }};
  font-weight: 800;
  font-size: ${(props) => {
    if (props.$size !== undefined) {
      switch (props.$size) {
        case 'L':
          return props.$size ? '15px' : '15px';
        case 'M':
          return props.$size ? '15px' : '15px';
        case 'S':
          return props.$size ? '13px' : '15px';
        case 'XS':
          return props.$size ? '11px' : '15px';
        default:
          return '15px';
      }
    } else {
      return '15px';
    }
  }};
  border-radius: ${(props) => (props.$appearance ? '12px 0 0 12px' : '12px')};
  gap: ${(props) => (props.$gap ? props.$gap : '0px')};
  width: ${(props) => (props.$fullWidth ? '100%' : 'unset')};
  background: ${(props) => {
    if (props.$typeBtn !== undefined) {
      switch (props.$typeBtn) {
        case 'black':
          return props.$typeBtn ? props.theme.newColor.primary : 'transparent';
        case 'gray':
          return props.$typeBtn ? props.theme.color.clear : 'transparent';
        case 'accent':
          return props.$typeBtn ? props.theme.color.accent : 'transparent';
        case 'primary':
          return props.$typeBtn ? props.theme.color.primary : 'transparent';
        case 'secondary':
          return props.$typeBtn ? props.theme.color.secondary : 'transparent';
        case 'flat':
          return 'transparent';
        case 'success':
          return props.$typeBtn ? props.theme.color.success : 'transparent';
        case 'danger':
          return props.$typeBtn ? props.theme.color.danger : 'transparent';
        case 'white':
          return props.$typeBtn ? '#ffffff' : 'transparent';
        default:
          return 'transparent';
      }
    } else {
      return 'transparent';
    }
  }};

  color: ${(props) => {
    if (props.$typeBtn !== undefined) {
      switch (props.$typeBtn) {
        case 'black':
          return props.$typeBtn ? props.theme.newColor.base01 : props.theme.text.base01;
        case 'gray':
          return props.$typeBtn ? props.theme.text.text01 : props.theme.text.text01;
        case 'primary':
          return props.$typeBtn ? '#fff' : 'unset';
        case 'accent':
          return props.$typeBtn ? '#fff' : 'unset';
        case 'secondary':
          return props.$typeBtn ? props.theme.text.link : 'unset';
        case 'flat':
          return props.$typeBtn ? props.theme.text.link : props.theme.text.text01;
        case 'success':
          return props.$typeBtn ? '#fff' : 'unset';
        case 'danger':
          return props.$typeBtn ? '#fff' : 'unset';
        case 'white':
          return props.$typeBtn ? '#2A2C32' : 'unset';
        default:
          return 'unset';
      }
    } else {
      return props.theme.text.text01;
    }
  }};
  svg {
    height: ${(props) => {
      if (props.$onlyIcon && props.$size !== undefined) {
        switch ((props.$onlyIcon, props.$size)) {
          case 'L':
            return props.$size ? '24px' : '20px';
          case 'M':
            return props.$size ? '24px' : '20px ';
          case 'S':
            return props.$size ? '20px' : '20px ';
          case 'XS':
            return props.$size ? '18px' : '20px ';

          default:
            return '20px';
        }
      }
    }};
    color: ${(props) => {
      if (props.$typeBtn !== undefined) {
        switch (props.$typeBtn) {
          case 'flat':
            return props.$typeBtn ? props.theme.text.link : props.theme.text.text01;
          default:
            return 'unset';
        }
      } else {
        return props.theme.text.text01;
      }
    }};
  }
  border: ${(props) => {
    if (props.$typeBtn !== undefined) {
      switch (props.$typeBtn) {
        case 'black':
          return props.$typeBtn ? '1px #2A2C32 solid' : 'none';
        case 'white':
          return props.$typeBtn ? '1px #C7CBD4 solid' : 'none';
        default:
          return 'none';
      }
    } else {
      return 'none';
    }
  }};

  &:not([disabled]):hover {
    background: ${(props) => {
      if (props.$typeBtn !== undefined) {
        switch (props.$typeBtn) {
          case 'black':
            return props.theme.newColor.primary;
          case 'gray':
            return props.theme.color.clearHover;
          case 'accent':
            return props.theme.color.accentHover;
          case 'primary':
            return props.theme.color.hoverBlue;
          case 'secondary':
            return props.theme.color.secondaryHover;
          case 'flat':
            return props.theme.color.secondaryHover;
          case 'success':
            return darken(0.1, props.theme.color.success);
          case 'danger':
            return darken(0.1, props.theme.color.danger);
          case 'white':
            return '#2A2C32';
          default:
            return 'transparent';
        }
      } else {
        return `${props.theme.color[props.$color]}11`;
      }
    }};
    color: ${(props) => {
      if (props.$typeBtn !== undefined) {
        switch (props.$typeBtn) {
          case 'black':
            return props.theme.newColor.base01;
          case 'gray':
            return props.theme.text.text01;
          case 'accent':
          case 'primary':
          case 'success':
          case 'danger':
          case 'white':
            return '#fff';
          default:
            return props.theme.text.link;
        }
      } else {
        return 'unset';
      }
    }};
    border: ${(props) => {
      if (props.$typeBtn !== undefined) {
        switch (props.$typeBtn) {
          case 'black':
            return props.$typeBtn ? '1px #C7CBD4 solid' : 'none';
          case 'white':
            return props.$typeBtn ? '1px #2A2C32 solid' : 'none';
          default:
            return 'none';
        }
      } else {
        return 'none';
      }
    }};
  }
  &:not([disabled]):active {
    background: ${(props) => {
      if (props.$typeBtn !== undefined) {
        switch (props.$typeBtn) {
          case 'black':
            return props.theme.newColor.primary;
          case 'gray':
            return props.theme.color.clear;
          case 'accent':
            return props.theme.color.accentActive;
          case 'primary':
            return props.theme.color.primaryActive;
          case 'secondary':
            return props.theme.color.secondaryActive;
          case 'flat':
            return props.theme.color.secondaryActive;
          case 'success':
            return darken(0.4, props.theme.color.success);
          case 'danger':
            return darken(0.4, props.theme.color.danger);
          default:
            return 'transparent';
        }
      } else {
        return props.theme.color[props.$color];
      }
    }};
    border: ${(props) => {
      if (props.$typeBtn !== undefined) {
        switch (props.$typeBtn) {
          case 'black':
            return props.$typeBtn ? '1px #EBEBEB solid' : 'none';
          case 'white':
            return props.$typeBtn ? '1px #EBEBEB solid' : 'none';
          default:
            return 'none';
        }
      } else {
        return 'none';
      }
    }};
    color: ${(props) => {
      switch (props.$typeBtn) {
        case 'black':
          return props.theme.newColor.base01;
        case 'gray':
        case 'success':
        case 'danger':
          return props.theme.text.text01;
        case 'secondary':
        case 'flat':
          return props.theme.text.link;
        case 'white':
          return '#2A2C32';
        default:
          return '#fff';
      }
    }};
  }
  &:disabled {
    cursor: default;
    opacity: 0.56;
    border: ${(props) => {
      if (props.$typeBtn !== undefined) {
        switch (props.$typeBtn) {
          case 'black':
            return props.$typeBtn ? '1px #EBEBEB solid' : 'none';
          case 'white':
            return props.$typeBtn ? '1px #EBEBEB solid' : 'none';
          default:
            return 'none';
        }
      } else {
        return 'none';
      }
    }};
  }
  &.reg {
    height: 44px;
    border-radius: 10px;
    width: 380px;
    align-self: center;
  }
  &.reg_clock {
    height: 48px;
    border-radius: 10px;
    width: fit-content;
    padding: 17px 28px;
    align-self: center;
  }
  &.h35 {
    height: 35px;
  }
  &.h50 {
    height: 50px;
    padding: 5px 48px;
    font-size: 13px;
    font-weight: 400;
    @media (max-width: 1600px) {
      height: 40px;
      padding: 5px 28px;
    }
  }
  &.h56 {
    height: 54px;
  }
  &.h63 {
    height: 63px;
    font-size: 14px;
    font-weight: 400;
  }
  &.unset {
    font-size: 13px;
    color: ${(props) => props.theme.color.mainDarkFull};
  }
  &.new {
    line-height: 15px;
    font-weight: 400;
    font-size: 13px;
    height: 38px;
  }

  &.messenger-create-button-white {
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    border: 1px solid ${(props) => props.theme.newColor.ghost};
    padding: 15px 27px;
  }

  &.messenger-edit-button-black {
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    border: 1px solid ${(props) => props.theme.newColor.primary};
    opacity: 100;
    padding: 15px 27px;
    &:disabled {
      cursor: default;
      opacity: 100s;
    }
    &.hover {
      border: 1px solid ${(props) => props.theme.newColor.primary};
    }
  }
  &.messenger-edit-button-trash {
    border: 1px solid ${(props) => props.theme.newColor.ghost};
    padding: 10px;
  }
  &.messenger-edit-button-trash:hover {
    background-color: ${(props) => props.theme.newColor.base01};
    border: 1px solid ${(props) => props.theme.newColor.ghost};
    padding: 10px;
  }
  &.messenger-edit-button-trash:not([disabled]):hover {
    background-color: ${(props) => props.theme.newColor.base01};
  }
  &.messenger-edit-button-trash:not([disabled]):active {
    background-color: ${(props) => props.theme.newColor.base01};
    border: 1px solid ${(props) => props.theme.newColor.ghost};
  }
`;

export const AppearanceArrow = styled.button<Pick<IButtonProps, '$size' | '$typeBtn'>>`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  cursor: pointer;
  transition: background ${DURATION} ease-in-out;

  justify-content: center;
  border-radius: 0 12px 12px 0;
  svg {
    height: ${(props) => {
      if (props.$size !== undefined) {
        switch (props.$size) {
          case 'L':
            return props.$size ? '24px' : '20px';
          case 'M':
            return props.$size ? '24px' : '20px';
          case 'S':
            return props.$size ? '20px' : '20px';
          case 'XS':
            return props.$size ? '18px' : '20px';

          default:
            return '20px';
        }
      } else {
        return '20px';
      }
    }};
    width: ${(props) => {
      if (props.$size !== undefined) {
        switch (props.$size) {
          case 'L':
            return props.$size ? '24px' : '20px';
          case 'M':
            return props.$size ? '24px' : '20px';
          case 'S':
            return props.$size ? '20px' : '20px';
          case 'XS':
            return props.$size ? '18px' : '20px';

          default:
            return '20px';
        }
      } else {
        return '20px';
      }
    }};
  }
  padding: ${(props) => {
    if (props.$size !== undefined) {
      switch (props.$size) {
        case 'L':
          return props.$size ? '16px' : '5px';
        case 'M':
          return props.$size ? '10px' : '5px';
        case 'S':
          return props.$size ? '6px' : '5px';
        case 'XS':
          return props.$size ? '2px' : '5px';

        default:
          return '5px 12px';
      }
    } else {
      return '5px 12px';
    }
  }};
  background: ${(props) => {
    if (props.$typeBtn !== undefined) {
      switch (props.$typeBtn) {
        case 'gray':
          return props.$typeBtn ? props.theme.color.clear : 'transparent';
        case 'accent':
          return props.$typeBtn ? props.theme.color.accent : 'transparent';
        case 'primary':
          return props.$typeBtn ? props.theme.color.primary : 'transparent';
        case 'secondary':
          return props.$typeBtn ? props.theme.color.secondary : 'transparent';
        case 'flat':
          return 'transparent';
        case 'success':
          return props.$typeBtn ? props.theme.color.success : 'transparent';
        case 'danger':
          return props.$typeBtn ? props.theme.color.danger : 'transparent';
        default:
          return 'transparent';
      }
    } else {
      return 'transparent';
    }
  }};

  color: ${(props) => {
    if (props.$typeBtn !== undefined) {
      switch (props.$typeBtn) {
        case 'gray':
          return props.$typeBtn ? props.theme.text.text01 : props.theme.text.text01;
        case 'primary':
          return props.$typeBtn ? '#fff' : 'unset';
        case 'accent':
          return props.$typeBtn ? '#fff' : 'unset';
        case 'secondary':
          return props.$typeBtn ? props.theme.text.link : 'unset';
        case 'flat':
          return props.$typeBtn ? props.theme.text.link : props.theme.text.text01;
        case 'success':
          return props.$typeBtn ? '#fff' : 'unset';
        case 'danger':
          return props.$typeBtn ? '#fff' : 'unset';
        default:
          return 'unset';
      }
    } else {
      return 'unset';
    }
  }};
  &:not([disabled]):hover {
    background: ${(props) => {
      if (props.$typeBtn !== undefined) {
        switch (props.$typeBtn) {
          case 'gray':
            return props.theme.color.clearHover;
          case 'accent':
            return props.theme.color.accentHover;
          case 'primary':
            return props.theme.color.hoverBlue;
          case 'secondary':
            return props.theme.color.secondaryHover;
          case 'flat':
            return props.theme.color.secondaryHover;
          case 'success':
            return darken(0.1, props.theme.color.success);
          case 'danger':
            return darken(0.1, props.theme.color.danger);
          default:
            return 'transparent';
        }
      } else {
        return `#fff`;
      }
    }};
    color: ${(props) => {
      if (props.$typeBtn !== undefined) {
        switch (props.$typeBtn) {
          case 'gray':
            return props.theme.text.text01;
          case 'accent':
          case 'primary':
          case 'success':
          case 'danger':
            return '#fff';
          default:
            return props.theme.text.link;
        }
      } else {
        return 'unset';
      }
    }};
  }
  &:not([disabled]):active {
    background: ${(props) => {
      if (props.$typeBtn !== undefined) {
        switch (props.$typeBtn) {
          case 'gray':
            return props.theme.color.clear;
          case 'accent':
            return props.theme.color.accentActive;
          case 'primary':
            return props.theme.color.darkBlue;
          case 'secondary':
            return props.theme.color.secondaryActive;
          case 'flat':
            return props.theme.color.secondaryActive;
          case 'success':
            return darken(0.4, props.theme.color.success);
          case 'danger':
            return darken(0.4, props.theme.color.danger);
          default:
            return 'transparent';
        }
      } else {
        return '#fff';
      }
    }};
    color: ${(props) => {
      switch (props.$typeBtn) {
        case 'gray':
        case 'success':
        case 'danger':
          return props.theme.text.text01;
        case 'secondary':
        case 'flat':
          return props.theme.text.link;
        default:
          return '#fff';
      }
    }};
  }
`;

export const ButtonWrapper = styled(FlexWithAlign)<Pick<IButtonProps, '$fullWidth'>>`
  width: ${(props) => (props.$fullWidth ? '100%' : 'unset')};
`;

export const ButtonOptions = styled(FlexWithAlign)`
  background: ${(props) => props.theme.color.base01};
  box-shadow: 1px 1px 5px 1px #dbdddf;
  padding: 12px;
  border-radius: 8px;
  width: calc(100% + 12px);
  position: absolute;
  top: 150%;
  right: 0;
  z-index: 4;
  &.open {
    visibility: visible;
    animation: ${scaleIn} 0.3s ease;
  }
  &.close {
    visibility: hidden;
    animation: ${scaleOut} 0.3s ease;
  }
`;
