import React, { PropsWithChildren, Suspense, useRef } from 'react';

import CreateFilialModal from './create/filial/CreateFilialModal';
import InputOutputCashModal from './create/finance/input-output-cash/InputOutputCashModal';
import CreateKassaTransactionModal from './create/finance/kassa-transaction/CreateKassaTransactionModal';
import TransferCashModal from './create/finance/transfer-cash/TransferCashModal';
import CreateChatModal from './create/messenger/CreateChatModal';
import AddNoteModal from './create/note/AddNoteModal';
import CreatePaymentModal from './create/payment/CreatePaymentModal';
import { CreateRecordingBlockModalAsync } from './create/recording/block/CreateRecordingBlockModalAsync';
import CreateClientRecordingModal from './create/recording/client/CreateClientRecordingModal';
import ConfirmActionModal from './create/recording/confirm/ConfirmActionModal';
import CreateServiceCategoryModal from './create/service/category/CreateServiceCategoryModal';
import CreateTimeTable from './create/timetable/CreateTimeTable';
import CommonConfirmDelete from './delete/CommonConfirmDelete';
import { ClientInfoAsync } from './info/client-info/ClientInfo.async';
import EmployeeScheduleModal from './update/employee-schedule/EmployeeScheduleModal';
import EditFilialTimeModal from './update/filial/EditFilialTimeModal';
import ProfileManagementModal from './update/profile-management/ProfileManagementModal';
import ChangeTarifModal from './update/tarif/ChangeTarifModal';

import CreateExportModal from '../../../pages/ClientPage/lib/client-export-csv/CreateExportModal';
import { useActions } from '../../../shared/lib/hooks/useActions';
import { useTypedSelector } from '../../../shared/lib/hooks/useTypedSelector';
import { Loader, Modal } from '../../../shared/ui';

import 'react-loading-skeleton/dist/skeleton.css';
import CreateWeekTimeTable from './create/week-timetable/CreateWeekTimeTable';
import CopyTimeTable from './create/copy-timetable/CopyTimeTable';
import ColorModal from './update/color/ColorModal';
import UpdateChatModal from './update/messenger/UpdateChatModal';
import CreateSaleProductCategoryModal from './create/category/CreateSaleProductCategoryModal';
import NewCommonConfirmDelete from './delete/new/NewCommonConfirmDelete';
import LinkedServicesModal from './create/service/linked/LinkedServicesModal';
import CreateTelegramBotModal from './create/telegram-bot/CreateTelegramBotModal';
import NewSalesModal from './create/sales/NewSalesModal';
import ClientImportInfoModal from './info/client-import-info/ClientImportInfoModal';
import ApplyPromocodeModal from './update/tarif/apply-promocode/ApplyPromocodeModal';
import PaymentsHistoryModal from './update/tarif/payments-history/PaymentsHistoryModal';
import ProfileExitModal from './update/profile-exit/ProfileExitModal';
import CashRegisterIconModal from './update/cash-register/icon/CashRegisterIconModal';
import { CreateNewRecordingModalAsync } from './create/recording/new-recording/CreateNewRecordingModalAsync';
import CropImageModal from './components/crop-image/CropImageModal';
import PolicyModal from './info/policy/PolicyModal';

interface IModalProps {
  children?: PropsWithChildren;
}

const CommonModal: React.FC<IModalProps> = (props) => {
  const { children } = props;
  const modalRef = useRef(null);
  const { closeModal, setChatSub } = useActions();
  const { showModal, modalName, modalSize, modalType, modalPayload, classModal, actionName, stateNum, skeleton } = useTypedSelector(
    (state) => state.modal
  );

  const modals = {
    EDIT_FILIAL_TIME: (
      <EditFilialTimeModal
        closeModal={closeModal}
        modalPayload={modalPayload}
      />
    ),
    CREATE_RECORDING_BLOCK: (
      <CreateRecordingBlockModalAsync
        modalPayload={modalPayload}
        onSuccess={closeModal}
      />
    ),
    CREATE_NEW_RECORDING: (
      <CreateNewRecordingModalAsync
        modalPayload={modalPayload}
        onSuccess={() => {
          setChatSub('main');
          // setActiveClient(null);
          closeModal();
        }}
      />
    ),
    CREATE_CLIENT_RECORDING: <CreateClientRecordingModal closeModal={closeModal} />,
    CREATE_KASSA_TRANSACTION: <CreateKassaTransactionModal closeModal={closeModal} />,

    EXPORT_CSV_CLIENT: <CreateExportModal closeModal={closeModal} />,
    IMPORT_CLIENT_INFO: <ClientImportInfoModal closeModal={closeModal} />,
    CREATE_FILIAL: <CreateFilialModal closeModal={closeModal} />,
    EDIT_FILIAL: (
      <CreateFilialModal
        closeModal={closeModal}
        edit={true}
        modalPayload={modalPayload}
      />
    ),
    CONFIRM_ACTION: (
      <ConfirmActionModal
        closeModal={closeModal}
        modalPayload={modalPayload}
      />
    ),
    CONFIRM_DELETE: (
      <CommonConfirmDelete
        closeModal={closeModal}
        modalPayload={modalPayload}
        actionName={actionName}
      />
    ),
    CONFIRM_NEW_DELETE: (
      <NewCommonConfirmDelete
        closeModal={closeModal}
        modalPayload={modalPayload}
        actionName={actionName}
      />
    ),
    CREATE_SERVICE_CATEGORY: (
      <CreateServiceCategoryModal
        closeModal={closeModal}
        modalPayload={modalPayload}
      />
    ),
    EDIT_SERVICE_CATEGORY: (
      <CreateServiceCategoryModal
        closeModal={closeModal}
        modalPayload={modalPayload}
        edit={true}
      />
    ),
    PROFILE_MANAGEMENT: (
      <ProfileManagementModal
        closeModal={closeModal}
        modalPayload={modalPayload}
      />
    ),
    UPDATE_TARIF: <ChangeTarifModal modalPayload={modalPayload} />,
    UPDATE_TARIF_MODULES: (
      <ChangeTarifModal
        modalPayload={modalPayload}
        edit={true}
      />
    ),

    UPDATE_EMPLOYEE_SCHEDULE: (
      <EmployeeScheduleModal
        modalPayload={modalPayload}
        closeModal={closeModal}
        stateNum={stateNum}
      />
    ),
    ADD_TIMETABLE_SCHEDULE: (
      <CreateTimeTable
        modalPayload={modalPayload}
        closeModal={closeModal}
      />
    ),
    EDIT_TIMETABLE_SCHEDULE: (
      <CreateTimeTable
        modalPayload={modalPayload}
        closeModal={closeModal}
        edit={true}
      />
    ),
    UPDATE_WEEK_TIMETABLE_SCHEDULE: (
      <CreateWeekTimeTable
        modalPayload={modalPayload}
        closeModal={closeModal}
        edit={true}
      />
    ),
    COPY_TIMETABLE_SCHEDULE: (
      <CopyTimeTable
        modalPayload={modalPayload}
        closeModal={closeModal}
        edit={true}
      />
    ),
    CLIENT_INFO: (
      <ClientInfoAsync
        modalPayload={modalPayload}
        closeModal={closeModal}
      />
    ),
    CREATE_CHAT: (
      <UpdateChatModal
        modalPayload={modalPayload}
        closeModal={closeModal}
      />
    ),
    UPDATE_CHAT: (
      <CreateChatModal
        modalPayload={modalPayload}
        closeModal={closeModal}
      />
    ),
    ADD_NOTE: (
      <AddNoteModal
        modalPayload={modalPayload}
        closeModal={closeModal}
        setisNoteOpen={undefined}
      />
    ),
    ADD_COLOR: (
      <ColorModal
        modalPayload={modalPayload}
        closeModal={closeModal}
      />
    ),
    ADD_SALEPRODUCT_CATEGORY: (
      <CreateSaleProductCategoryModal
        modalPayload={modalPayload}
        closeModal={closeModal}
        setIsCreateCategoryOpen={undefined}
      />
    ),
    LINKED_SERVICES: (
      <LinkedServicesModal
        modalPayload={modalPayload}
        closeModal={closeModal}
      />
    ),
    ON_TELEGRAM_BOT: (
      <CreateTelegramBotModal
        modalPayload={modalPayload}
        closeModal={closeModal}
      />
    ),
    NEW_SALES_ADD_CLIENT: (
      <NewSalesModal
        modalPayload={modalPayload}
        closeModal={closeModal}
      />
    ),
    APPLY_PROMOCODE: <ApplyPromocodeModal closeModal={closeModal} />,
    PAYMENTS_HISTORY: <PaymentsHistoryModal closeModal={closeModal} />,
    PROFILE_EXIT: (
      <ProfileExitModal
        modalPayload={modalPayload}
        closeModal={closeModal}
      />
    ),
    CASH_REGISTER_ICON: (
      <CashRegisterIconModal
        closeModal={closeModal}
        cashRegister={modalPayload}
      />
    ),
    PAYMENT: (
      <CreatePaymentModal
        modalPayload={modalPayload}
        closeModal={closeModal}
      />
    ),
    INPUT_OUTPUT_CASH: <InputOutputCashModal closeModal={closeModal} />,
    TRANSFER_CASH: <TransferCashModal closeModal={closeModal} />,
    CROP_IMAGE: (
      <CropImageModal
        modalPayload={modalPayload}
        closeModal={closeModal}
      />
    ),
    POLICY_MODAL: (
      <PolicyModal
        closeModal={closeModal}
        modalPayload={modalPayload}
      />
    ),
    '': ''
  };

  return (
    <>
      <>
        {showModal && (
          <Modal
            isOpen={showModal}
            onClose={() => closeModal()}
            ref={modalRef}
            type={modalType}
            size={modalSize}
            className={classModal ? classModal : 'common'}
          >
            <Suspense fallback={skeleton ? skeleton : <Loader />}>
              <>
                {modals[modalName]}
                {children}
              </>
            </Suspense>
          </Modal>
        )}
      </>
    </>
  );
};

export default CommonModal;
