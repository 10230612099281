import styled from 'styled-components';
import { FlexWithAlign } from '../../../../utils/styleUtils';
import { DURATION, MODAL_GAP, MODAL_TEXT_GAP, RADIUS_M, RADIUS_S, SHADOW_INPUT } from '../../../../styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .title {
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
    color: #151618;
  }
  .top-wrapper {
    padding: 0 6.6px;
    width: 100%;
    gap: 22px;
  }
  &.top {
    margin-top: 0;
  }
  .form-wrapper {
    gap: 10px;
  }
  .names-container {
    width: calc(100% + 6px);
    margin-top: 14px;
    overflow-y: auto;
  }
`;

export const DialogContainer = styled.div`
  background: #fff;
  z-index: 10;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .top-wrapper {
    padding: 0 6.6px;
    width: 100%;
  }
`;
export const DialogHeader = styled(FlexWithAlign)``;
export const DialogWrapper = styled(FlexWithAlign)`
  //max-width: 482px;
  margin: 0 auto;
  width: 100%;
  flex-direction: column;
  gap: ${MODAL_GAP};
`;
export const UserCardWrapper = styled(FlexWithAlign)`
  max-height: 20rem;
`;
export const UserCard = styled.div<{ grid: string; isSelected: boolean }>`
  display: grid;
  grid-template-columns: ${(props) => props.grid || '1fr 5fr'};
  align-items: center;
  padding: 12px 0;
  margin-inline: 12px;
  border-bottom: 1px solid ${(props) => props.theme.color.base03};
  cursor: pointer;
  transition: 0.3s ease;
  &:hover {
    background: ${(props) => props.theme.color.base02};
  }
  &.messenger:hover,
  &.registration-location:hover,
  &.registration-service:hover {
    background: transparent;
  }

  span {
    width: 24px;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 24px;
    background: transparent;
    border-radius: ${RADIUS_S};
    transition: background ${DURATION} ease-in-out;
    &:after {
      transition-property: border-color, color;
      transition-duration: ${DURATION};
      transition-timing-function: ease-in-out;
      position: absolute;
      pointer-events: none;
      inset: 0;
      content: '';
      border-radius: inherit;
      border: 1px solid currentColor;
      color: ${(props) => props.theme.color.base04};
    }
    svg {
      color: #fff;
      transition: scale ${DURATION} ease-in-out;
      transform: scale(0);
    }
    &.active {
      background: ${(props) => props.theme.text.link};
      &:after {
        border-color: ${(props) => props.theme.color.primary};
      }
      svg {
        transform: scale(1);
      }
    }
    &.span-on-createchat {
      width: 23px;
      height: 23px;
      border-radius: 0.375rem;
    }
    &.active-create-chat-default {
      &:hover {
        background-color: transparent;
      }
      &:after {
        border-color: ${(props) => props.theme.newColor.grey1};
      }
      svg {
        transform: scale(1);
      }
    }
    &.active-create-chat {
      &:after {
        border-color: ${(props) => props.theme.newColor.black};
      }
      svg {
        transform: scale(1);
      }
    }
  }
  &.messenger {
    font-size: 14px;
    font-weight: 400;
    padding: 16px 0;
    margin-inline: auto;
  }
  &.registration-location {
    width: 100%;
  }
  &.registration-service {
    border: none;
  }

  //&:last-child {
  //  margin-bottom: 0;
  //}
`;

export const UserItemContainer = styled(FlexWithAlign)`
  padding-block: 17px;
  border-bottom: 1px solid ${(props) => props.theme.color.base10};
  gap: 12px
`;

export const UsetItemTextContainer = styled.div`
  width: 100%;
`;

export const ConfirmDialog = styled.div`
  border-radius: 8px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  > ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${MODAL_TEXT_GAP};
    > div {
      padding: 6px 16px;
      width: 100%;
      box-shadow: ${SHADOW_INPUT};
      border-radius: ${RADIUS_M};
    }
  }
`;

export const CloseBtn = styled.div`
  padding-top: 12px;
  cursor: pointer;
`;
