import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(weekOfYear);
import { executeSequentially } from './CopyTimeTable.data';
import {
  ButtonsWrapper,
  ChooseWeekItem,
  ChooseWeekList,
  ChosenWeek,
  Content,
  EmployeesContent,
  EmployeesContentWrapper,
  EmployeesItem,
  EmployeesList,
  LabelArrow,
  MainContentLeft,
  MainContentRight,
  MainContentWrapper,
  TopWrapper,
  Wrapper
} from './CopyTimeTable.styled';

import { ISelectItem } from '../../../../../pages/EmployeesPage/ui/employee-timetable/ui/EmployeeTimeTablePage';
import { USERS_SCHEDULE_QUERY } from '../../../../../shared/const/query.const';
import { useActions } from '../../../../../shared/lib/hooks/useActions';
import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';
import NewButton from '../../../../../shared/new-ui/NewButton/ui/NewButton';
import NewCheckButton from '../../../../../shared/new-ui/NewCheckButton/ui/NewCheckButton';
import NewCustomCheckbox from '../../../../../shared/new-ui/NewCustomCheckbox/ui/NewCustomCheckbox';
import NewDropdown from '../../../../../shared/new-ui/NewDropdown/ui/NewDropdown';
import NewText from '../../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType, TextType } from '../../../../../shared/new-ui/NewText/ui/NewText.props';
import { IModalProps } from '../../../../../store/redux/modal/modal.interface';
import { useCopyScheduleTemplate } from '../../../../../store/redux/user/hooks/useUsersMutation';
import { useAllUsersScheduleQuery, useUsersQuery } from '../../../../../store/redux/user/hooks/useUsersQuery';
import { getWeeksOfYear, IWeek } from '../../../../../utils/date-events';
import { groupConsecutiveIncreasingByProperty } from '../../../../../utils/helperFunctions';
import { FlexWithAlign } from '../../../../../utils/styleUtils';
import { EIcon, IconNew } from '../../../../icons/medium-new-icons/icon';

import { toast } from 'react-hot-toast';

import { useQueryClient } from '@tanstack/react-query';

const CopyTimeTable: FC<IModalProps> = (props) => {
  const { closeModal } = props;
  const { activeDate, chosenWeek, chosenWeekList } = useTypedSelector((state1) => state1.calendar);
  const { chosenEmployes } = useTypedSelector((state1) => state1.modal);
  const queryClient = useQueryClient();
  const { setActiveDate, updateChosenWeekList, updateChosenEmployes, setChosenEmployes, clearChosenEmployes, clearChosenWeekList } =
    useActions();
  const weekList = getWeeksOfYear(dayjs(activeDate ? activeDate : '').year());
  const [dateOptions, setDateOptions] = useState(weekList);
  const { data: users } = useUsersQuery();

  const { data: employeeSchedules } = useAllUsersScheduleQuery({
    ids: users?.map((item) => item.id),
    dateStart: chosenWeek[0],
    dateEnd: chosenWeek[chosenWeek.length - 1]
  });
  const { mutateAsync: copyScheduleTemplate } = useCopyScheduleTemplate();
  const { t } = useTranslation();

  const handleChangeDate = (option: ISelectItem) => {
    setActiveDate(option.value);
  };

  const handleChooseWeek = (data: IWeek) => {
    updateChosenWeekList(data);
  };
  const handleSetTemplateSchedule = async () => {
    // const res = await copyScheduleTemplate({id})
    if (!employeeSchedules || chosenWeekList.length === 0 || chosenEmployes.length === 0) return;
    const groupedWeekList = groupConsecutiveIncreasingByProperty(chosenWeekList, 'weekNum');
    const promiseGroups = chosenEmployes.map((emp) =>
      groupedWeekList.map(
        (week) => () =>
          copyScheduleTemplate({
            id: emp.employeeId,
            data: {
              datestart: dayjs(week[0].value).format('YYYY-MM-DD'),
              timework: [
                {
                  monday: {
                    timeend:
                      employeeSchedules.find(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'monday'
                      )?.timeend || '',
                    timestart:
                      employeeSchedules.find(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'monday'
                      )?.timestart || '',
                    break:
                      employeeSchedules.filter(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'monday'
                      ).length > 0 &&
                      employeeSchedules.filter(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'monday'
                      )[0]?.breaks !== undefined
                        ? employeeSchedules
                            .find(
                              (schedule) =>
                                schedule.employeeId === emp.employeeId &&
                                dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'monday'
                            )
                            ?.breaks?.map((item) => {
                              return {
                                breakfinish: item.bend,
                                breakstart: item.bstart
                              };
                            })
                        : null
                  },
                  tuesday: {
                    timeend:
                      employeeSchedules.find(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'tuesday'
                      )?.timeend || '',
                    timestart:
                      employeeSchedules.find(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'tuesday'
                      )?.timestart || '',
                    break:
                      employeeSchedules.filter(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'tuesday'
                      ).length > 0 &&
                      employeeSchedules.filter(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'tuesday'
                      )[0]?.breaks !== undefined
                        ? employeeSchedules
                            .find(
                              (schedule) =>
                                schedule.employeeId === emp.employeeId &&
                                dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'tuesday'
                            )
                            ?.breaks?.map((item) => {
                              return {
                                breakfinish: item.bend,
                                breakstart: item.bstart
                              };
                            })
                        : null
                  },
                  wednesday: {
                    timeend:
                      employeeSchedules.find(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'wednesday'
                      )?.timeend || '',
                    timestart:
                      employeeSchedules.find(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'wednesday'
                      )?.timestart || '',
                    break:
                      employeeSchedules.filter(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'wednesday'
                      ).length > 0 &&
                      employeeSchedules.filter(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'wednesday'
                      )[0]?.breaks !== undefined
                        ? employeeSchedules
                            .find(
                              (schedule) =>
                                schedule.employeeId === emp.employeeId &&
                                dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'wednesday'
                            )
                            ?.breaks?.map((item) => {
                              return {
                                breakfinish: item.bend,
                                breakstart: item.bstart
                              };
                            })
                        : null
                  },
                  thursday: {
                    timeend:
                      employeeSchedules.find(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'thursday'
                      )?.timeend || '',
                    timestart:
                      employeeSchedules.find(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'thursday'
                      )?.timestart || '',
                    break:
                      employeeSchedules.filter(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'thursday'
                      ).length > 0 &&
                      employeeSchedules.filter(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'thursday'
                      )[0]?.breaks !== undefined
                        ? employeeSchedules
                            .find(
                              (schedule) =>
                                schedule.employeeId === emp.employeeId &&
                                dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'thursday'
                            )
                            ?.breaks?.map((item) => {
                              return {
                                breakfinish: item.bend,
                                breakstart: item.bstart
                              };
                            })
                        : null
                  },
                  friday: {
                    timeend:
                      employeeSchedules.find(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'friday'
                      )?.timeend || '',
                    timestart:
                      employeeSchedules.find(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'friday'
                      )?.timestart || '',
                    break:
                      employeeSchedules.filter(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'friday'
                      ).length > 0 &&
                      employeeSchedules.filter(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'friday'
                      )[0]?.breaks !== undefined
                        ? employeeSchedules
                            .find(
                              (schedule) =>
                                schedule.employeeId === emp.employeeId &&
                                dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'friday'
                            )
                            ?.breaks?.map((item) => {
                              return {
                                breakfinish: item.bend,
                                breakstart: item.bstart
                              };
                            })
                        : null
                  },
                  saturday: {
                    timeend:
                      employeeSchedules.find(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'saturday'
                      )?.timeend || '',
                    timestart:
                      employeeSchedules.find(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'saturday'
                      )?.timestart || '',
                    break:
                      employeeSchedules.filter(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'saturday'
                      ).length > 0 &&
                      employeeSchedules.filter(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'saturday'
                      )[0]?.breaks !== undefined
                        ? employeeSchedules
                            .find(
                              (schedule) =>
                                schedule.employeeId === emp.employeeId &&
                                dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'saturday'
                            )
                            ?.breaks?.map((item) => {
                              return {
                                breakfinish: item.bend,
                                breakstart: item.bstart
                              };
                            })
                        : null
                  },
                  sunday: {
                    timeend:
                      employeeSchedules.find(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'sunday'
                      )?.timeend || '',
                    timestart:
                      employeeSchedules.find(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'sunday'
                      )?.timestart || '',
                    break:
                      employeeSchedules.filter(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'sunday'
                      ).length > 0 &&
                      employeeSchedules.filter(
                        (schedule) =>
                          schedule.employeeId === emp.employeeId &&
                          dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'sunday'
                      )[0]?.breaks !== undefined
                        ? employeeSchedules
                            .find(
                              (schedule) =>
                                schedule.employeeId === emp.employeeId &&
                                dayjs(schedule.date).locale('en').format('dddd').toLocaleLowerCase() === 'sunday'
                            )
                            ?.breaks?.map((item) => {
                              return {
                                breakfinish: item.bend,
                                breakstart: item.bstart
                              };
                            })
                        : null
                  }
                }
              ],
              weeks: week.length
            }
          })
      )
    );
    executeSequentially(promiseGroups.flat(1))
      .then((results) => {
        if (results.every((item) => item?.status === 200)) {
          toast.success('График обновлен');
          clearChosenWeekList();
          closeModal!();
          queryClient.invalidateQueries({
            queryKey: [USERS_SCHEDULE_QUERY]
          });
        }
      })
      .catch((err) => {
        toast.error('Ошибка при обновлении графика');
        console.error(err);
      });
  };
console.log(employeeSchedules)
  return (
    <Wrapper>
      <Content>
        <TopWrapper>
          <NewButton
            typeBtn={'close'}
            onClick={() => {
              clearChosenWeekList();
              closeModal();
            }}
          >
            <IconNew name={EIcon.close} />
          </NewButton>
          <NewText
            $tag={TextType.H2}
            $customSize={TextCustomType.T28}
            fontWeight={600}
            color={'title'}
          >
            {t('Копировать расписание')}
          </NewText>
        </TopWrapper>
        <MainContentWrapper>
          <MainContentLeft>
            <NewText
              $tag={TextType.H4}
              $customSize={TextCustomType.T16}
              fontWeight={600}
              color={'title'}
              className={'chosen-timetable-title'}
            >
              {t('Выбранное расписание')}
            </NewText>
            <FlexWithAlign $column>
              <NewDropdown
                onChange={(option: ISelectItem) => handleChangeDate(option)}
                placeholder={'Неделя'}
                options={dateOptions}
                currentValue={dateOptions.find((option) => dayjs(option.value).week() === dayjs(activeDate).week())?.label}
              />
              <ChosenWeek>
                <NewText
                  $customSize={TextCustomType.T16}
                  fontWeight={700}
                >
                  {t(
                    `Неделя #${dayjs(chosenWeek[0]).week()} • ${dayjs(chosenWeek[0]).format('D MMMM')} - ${dayjs(chosenWeek[6]).format(
                      'D MMMM'
                    )}`
                  )}
                </NewText>
              </ChosenWeek>
            </FlexWithAlign>
            <LabelArrow>
              <IconNew name={EIcon.copytimetablearrow} />
            </LabelArrow>
          </MainContentLeft>
          <MainContentRight>
            <NewText
              $tag={TextType.H4}
              $customSize={TextCustomType.T16}
              fontWeight={600}
              color={'title'}
              className={'apply-timetable-title'}
            >
              {t('Применить')}
            </NewText>
            {weekList.length > 0 && (
              <ChooseWeekList>
                {weekList
                  .filter((week) => dayjs(week.value).isAfter(dayjs()))
                  .map((week) => (
                    <ChooseWeekItem key={week.value}>
                      <NewCustomCheckbox
                        name={dayjs(week.value).format('DD-MM-YYYY')}
                        value={chosenWeekList.find((chosenWeek) => chosenWeek.value === week.value) !== undefined}
                        onChange={() => handleChooseWeek(week)}
                      />
                      <NewText $customSize={TextCustomType.T16}>{t(`Неделя #${dayjs(week.value).week()} • ${week.label}`)}</NewText>
                    </ChooseWeekItem>
                  ))}
              </ChooseWeekList>
            )}
          </MainContentRight>
        </MainContentWrapper>
        <EmployeesContentWrapper>
          <div>
            <NewText
              $tag={TextType.H4}
              $customSize={TextCustomType.T16}
              fontWeight={600}
              color={'title'}
            >
              {t('Для сотрудников')}
            </NewText>
          </div>
          <EmployeesContent>
            {users && users.length > 0 ? (
              <EmployeesList>
                <EmployeesItem key={'all'}>
                  <NewCheckButton
                    checked={chosenEmployes.length === users.length}
                    onCheck={() => {
                      if (chosenEmployes.length === users.length) {
                        clearChosenEmployes();
                      } else {
                        setChosenEmployes(
                          users.map((user) => {
                            return {
                              employeeId: user.id
                            };
                          })
                        );
                      }
                    }}
                  />
                  <NewText>{t('Все')}</NewText>
                </EmployeesItem>
                {users.map((user) => (
                  <EmployeesItem key={user.id}>
                    <NewCheckButton
                      checked={chosenEmployes.find((emp) => emp.employeeId === user.id) !== undefined}
                      name={user.fio}
                      onCheck={() => updateChosenEmployes({ employeeId: user.id })}
                    />
                    <NewText>{t(`${user.fio.split(' ')[0] || ''}`)}</NewText>
                  </EmployeesItem>
                ))}
              </EmployeesList>
            ) : null}
          </EmployeesContent>
        </EmployeesContentWrapper>
        <ButtonsWrapper>
          <NewButton
            onClick={() => {
              clearChosenWeekList();
              closeModal();
            }}
          >
            <span>{t('Отмена')}</span>
          </NewButton>
          <NewButton
            typeBtn={'black'}
            disabled={chosenEmployes.length === 0 || chosenWeekList.length === 0}
            onClick={handleSetTemplateSchedule}
          >
            <span>{t('Сохранить')}</span>
          </NewButton>
        </ButtonsWrapper>
      </Content>
    </Wrapper>
  );
};

export default CopyTimeTable;
