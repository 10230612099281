import { FC, ReactNode } from 'react';

interface IProps {
  children: ReactNode;
}

const AnimatedPage: FC<IProps> = ({ children }) => {
  return (
    <>
      <>{children}</>
    </>
  );
};

export default AnimatedPage;
