import Slider from 'react-slick';

import styled from 'styled-components';

import themelight from '../../../app/providers/ThemeProvider/ui/theme';
import CommonButton from '../../../shared/ui/Button/ui/CommonButton';
import { DURATION, RADIUS_S, translateXIn, translateXOut, Z_INDEX } from '../../../styles';
import { FlexWithAlign } from '../../../utils/styleUtils';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${themelight.newColor.base01};
`;

export const Wrapper = styled.div`
  height: 100%;
  .show-context {
    position: relative;
    .recording-overlay {
      z-index: 100;
    }
    .fc-media-screen {
      position: relative;
      // z-index: 101;
    }
    &::after {
      // content: '';
      // position: fixed;
      // top: 0;
      // left: 0;
      // width: 100%;
      // height: 100%;
      // background-color: rgba(0, 0, 0, 0.4);
      // z-index: 50;
      // transition: all 0.3s ease;
    }
  }
  .fc-resourceDayGridMonth-view *[data-resource-id='${(props: any) => props.itemID}'],
  td[data-resource-id='${(props: any) => props.itemID}'].fc-day-today.fc-timegrid-col.fc-resource,
  .fc-resourceTimeGridWeek-view *[data-resource-id='${(props: any) => props.itemID}'],
  td[data-resource-id='${(props: any) => props.itemID}'].fc-day-today.fc-timegrid-col.fc-resource {
    width: 100%;
    opacity: 1 !important;
  }
  .fc-resourceTimeGridDay-view *[data-resource-id='${(props: any) => props.itemID}'] {
    width: auto !important;
  }
  .fc-resourceTimeGridDay-view .fc-scrollgrid-sync-inner {
    > div:not(:nth-child(2)) {
      display: none;
    }
  }
  .fc.fc-non-business {
    background: transparent !important;
  }
  .fc-day.fc-day-tue.fc-day-today.fc-timegrid-col {
    background: transparent;
  }
  .fc .fc-timegrid-col.fc-day-today {
    background-color: transparent;
  }
  .fullcalendarWrapper {
    position: relative;
    width: 100%;
    > div {
      &:first-child {
        transition: 0.3s ease;
        scroll-behavior: smooth;
      }
    }
  }

  .fc-direction-ltr .fc-timegrid-col-events {
    margin: 0;
  }
  .fc-timegrid-event-harness.fc-timegrid-event-harness-inset {
    margin: 1px;
    box-shadow: none;
  }

  .fc-timegrid-event-harness-inset .fc-timegrid-event,
  .fc-timegrid-event.fc-event-mirror,
  .fc-timegrid-more-link {
    box-shadow: none;
  }
  .fc-theme-standard td,
  .fc-theme-standard th {
    border-right: none;
    border-left: none;
    border-color: ${(props) => props.theme.newColor.base10};
  }
  .fc-scrollgrid {
    border: none;
  }

  .fc-scroller-harness {
    overflow: visible;
  }
  thead .fc-scroller-harness {
    border-top: 1px solid ${(props) => props.theme.color.base03};
    border-bottom: 1px solid ${(props) => props.theme.color.base03};
  }

  //.fc-resourceDayGridMonth-view .fc-theme-standard td,
  //.fc-resourceDayGridMonth-view .fc-theme-standard th {
  //  border-right: none;
  //  border-left: 1px solid #eaebee;
  //}
  .fc-timegrid-slot-label-cushion {
    width: 74px !important;
    display: block !important;
    white-space: nowrap !important;
    overflow: visible !important;
    text-overflow: unset !important;
    position: relative;
    border: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5;
    color: ${themelight.newColor.secondary};
    text-align: center;
    z-index: 0;
    left: 0;
    top: -10px;
  }
  .fc-timegrid-slot-label {
    border: none !important;
  }
  .fc .fc-col-header-cell-cushion {
    font-size: 14px;
    color: ${(props) => props.theme.color.mainDark};
    opacity: 1;
    font-weight: 500;
  }
`;

export const ContentWrap = styled(FlexWithAlign)`
  // margin-top: 73px;
`;

export const ModalGrid = styled.div`
  display: grid;
  gap: 26px;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  &.gap26 {
    gap: 26px;
  }
  &.gap60 {
    grid-template-columns: auto 1fr;
    align-items: center;
    > div {
      p {
        display: flex;
      }
    }
  }
  @media (max-width: 1024px) {
    gap: 10px;
  }
  &.classificator {
    grid-template-columns: 1fr 1fr 0.1fr;
    align-items: center;
    button {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const MainColumn = styled.div`
  width: 100%;
  margin: 0 auto;
  .sticky-block {
    width: 100%;
  }
  .container {
    padding: 0;
  }
`;

export const MainColumnBottom = styled(FlexWithAlign)``;

export const SidebarWrapper = styled.div`
  height: 100vh;
  position: sticky;
  top: 0;
  background: ${(props) => props.theme.color.base01};
  border-radius: 0;
  transition-property: opacity, width, padding;
  transition-duration: ${DURATION};
  transition-timing-function: ease-in-out;
  opacity: 0;
  z-index: ${Z_INDEX};
  max-width: 218px;
  width: 100%;
  flex-shrink: 0;
  display: none;
  pointer-events: auto;
  border-right: 1px solid ${themelight.newColor.base08};

  // *,
  // *:before,
  // *:after {
  //   transition-property: visibility;
  //   transition-duration: ${DURATION};

  //   transition-timing-function: ease-in-out;
  //   visibility: hidden;
  //   opacity: 0;
  // }
  animation-duration: ${DURATION};
  animation-timing-function: ease-in-out;
  // animation-name: ${translateXOut};

  &.open-sidebar {
    opacity: 1;
    display: block;
    animation-duration: ${DURATION};
    transition-duration: ${DURATION};
    transition-property: visibility;
    transition-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    // animation-name: ${translateXIn};
    visibility: visible;
    // *,
    // *:before,
    // *:after {
    //   transition-property: visibility;
    //   transition-duration: ${DURATION};

    //   transition-timing-function: ease-in-out;
    //   visibility: visible;
    //   opacity: 1;
    // }
  }
`;
export const RecordingFixedContainer = styled(FlexWithAlign)`
  position: fixed;
  left: 56px;
  right: 0;
  top: 73px;
  background: ${(props) => props.theme.color.base01};
  z-index: 2;
  gap: 0;
  margin-bottom: 9px;
  // transition: transform 0.2s linear;
  // transform: translateY(0px) translateZ(0px);
  &.sticky {
    // transform: translateY(73px) translateZ(0px) !important;
    // margin-top: 73px;
    // transition: margin 0.3s ease;
  }
`;
export const TableRecordingHeader = styled(FlexWithAlign)`
  padding-left: 74px;
  // position: sticky;
  top: 0;
  height: 64px;

  z-index: 2;
  background: ${(props) => props.theme.color.base01};
  box-shadow: 0px 4px 8px 0px #312f331a;
  // transition: all 0.2s ease;
`;
export const TableRecordingDate = styled(FlexWithAlign)`
  width: 50px;
  min-width: 50px;
  &.hide {
    visibility: hidden;
  }
`;
export const WeekItem = styled(FlexWithAlign)`
  width: 100%;
  padding-bottom: 4px;
  background: ${(props) => props.theme.color.base01};
  cursor: pointer;
  border-radius: ${RADIUS_S};
  transition: background ${DURATION} ease-in-out;
  &:hover {
    background: ${(props) => props.theme.color.base02};
  }
`;

export const TodayBtn = styled(CommonButton)`
  position: fixed;
  bottom: 100px;
  right: 1rem;
  z-index: 9;
  &.center {
    transform: translateX(50%);
    right: 50%;
  }
  &.left {
    right: auto;
    left: 1rem;
  }
`;

export const DaySlider = styled(Slider)`
  padding: 0 !important;
  .slick-list,
  .slick-slider,
  .slick-track {
    padding: 0 !important;
  }
  max-width: calc(100% - 1rem);
  margin-bottom: 1rem;
  left: 0px;
  height: 50px;
  border-radius: 6px;
  position: relative;
  background: ${(props) => props.theme.color.base02};
  &.month {
    left: 0;
    margin: 0 auto 1rem;
    max-width: calc(100% - 2rem);
  }
`;

export const RecordingFixedHeaderContainer = styled(FlexWithAlign)`
  background-color: ${themelight.newColor.base01};
  z-index: 20;
  // position: fixed;
  // top: 0;
  // left: 56px;
  // width: calc(100% - 56px);
`;

export const RecordingFixedSideContainer = styled(FlexWithAlign)`
  background-color: ${themelight.newColor.base01};
  z-index: 20;
  &.sticky {
    margin-top: 73px;
  }
`;
