import React from 'react';

export function ScheduleClock() {
  return (
    <svg
      width='204'
      height='205'
      viewBox='0 0 104 105'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_dddi_11652_20397)'>
        <g clipPath='url(#clip0_11652_20397)'>
          <rect
            x='2.99121'
            y='3'
            width='98.0172'
            height='98.0172'
            rx='49.0086'
            fill='#E9E9E9'
          />
          <circle
            cx='51.9994'
            cy='52.0088'
            r='43.487'
            stroke='#8C8B88'
            strokeWidth='1.73284'
            strokeMiterlimit='4.13357'
            strokeDasharray='0.58 2.83'
          />
          <circle
            cx='51.9994'
            cy='52.0088'
            r='42.9094'
            stroke='#8C8B88'
            strokeWidth='2.88807'
            strokeMiterlimit='15.9925'
            strokeDasharray='1.73 17.33'
          />
          <path
            d='M51.2905 20.2488V21.3448H47.2745V20.2488H51.2905ZM47.7305 16.8008C48.1465 16.8008 48.4505 16.7102 48.6425 16.5288C48.8399 16.3475 48.9412 16.0595 48.9465 15.6648H50.0025V21.3448H48.7785V17.7768H47.3385V16.8008H47.7305ZM51.9754 17.5048C51.9914 17.1262 52.0874 16.7902 52.2634 16.4968C52.4394 16.1982 52.6848 15.9635 52.9994 15.7928C53.3141 15.6222 53.6768 15.5368 54.0874 15.5368C54.4821 15.5368 54.8341 15.6142 55.1434 15.7688C55.4581 15.9235 55.7008 16.1368 55.8714 16.4088C56.0474 16.6808 56.1354 16.9848 56.1354 17.3208C56.1354 17.6942 56.0208 18.0328 55.7914 18.3368C55.5674 18.6355 55.2661 18.8808 54.8874 19.0728C54.5728 19.2328 54.2661 19.4195 53.9674 19.6328C53.6741 19.8408 53.4954 20.0462 53.4314 20.2488H56.1434V21.3448H51.8794C51.8794 20.8382 51.9328 20.4222 52.0394 20.0968C52.1514 19.7662 52.3221 19.4808 52.5514 19.2408C52.7861 18.9955 53.1141 18.7368 53.5354 18.4648C53.6528 18.3955 53.7648 18.3288 53.8714 18.2648C53.9461 18.2168 54.0181 18.1715 54.0874 18.1288C54.3434 17.9795 54.5328 17.8462 54.6554 17.7288C54.7834 17.6062 54.8474 17.4648 54.8474 17.3048C54.8474 17.1822 54.8128 17.0702 54.7434 16.9688C54.6794 16.8622 54.5888 16.7795 54.4714 16.7208C54.3594 16.6622 54.2314 16.6328 54.0874 16.6328C53.8421 16.6328 53.6474 16.7235 53.5034 16.9048C53.3594 17.0808 53.2794 17.3128 53.2634 17.6008L51.9754 17.5048Z'
            fill='#8C8B88'
          />
          <path
            d='M14.8164 53.355C14.8591 53.5043 14.9444 53.6243 15.0724 53.715C15.2058 53.8056 15.3604 53.851 15.5364 53.851C15.8778 53.851 16.1391 53.6856 16.3204 53.355C16.5018 53.019 16.5978 52.5256 16.6084 51.875L17.0884 51.899C16.9658 52.2296 16.7604 52.499 16.4724 52.707C16.1898 52.9096 15.8804 53.011 15.5444 53.011C15.1604 53.011 14.8164 52.9283 14.5124 52.763C14.2138 52.5923 13.9791 52.3576 13.8084 52.059C13.6431 51.755 13.5604 51.411 13.5604 51.027C13.5604 50.6323 13.6511 50.283 13.8324 49.979C14.0138 49.675 14.2644 49.4376 14.5844 49.267C14.9098 49.0963 15.2804 49.011 15.6964 49.011C16.0538 49.011 16.3764 49.0856 16.6644 49.235C16.9524 49.379 17.1924 49.5923 17.3844 49.875C17.5338 50.0936 17.6484 50.3523 17.7284 50.651C17.8084 50.9496 17.8484 51.2803 17.8484 51.643C17.8484 52.2776 17.7711 52.8403 17.6164 53.331C17.4671 53.8216 17.2191 54.2136 16.8724 54.507C16.5311 54.8003 16.0858 54.947 15.5364 54.947C15.2271 54.947 14.9364 54.883 14.6644 54.755C14.3978 54.627 14.1684 54.4483 13.9764 54.219C13.7898 53.9843 13.6591 53.715 13.5844 53.411L14.8164 53.355ZM16.5124 51.027C16.5124 50.8456 16.4778 50.6856 16.4084 50.547C16.3444 50.4083 16.2484 50.3016 16.1204 50.227C15.9978 50.147 15.8564 50.107 15.6964 50.107C15.5364 50.107 15.3898 50.147 15.2564 50.227C15.1284 50.3016 15.0298 50.4083 14.9604 50.547C14.8911 50.6856 14.8564 50.8456 14.8564 51.027C14.8564 51.203 14.8884 51.3576 14.9524 51.491C15.0218 51.6243 15.1151 51.7283 15.2324 51.803C15.3551 51.8776 15.4911 51.915 15.6404 51.915C15.8111 51.915 15.9604 51.8776 16.0884 51.803C16.2218 51.7283 16.3258 51.6243 16.4004 51.491C16.4751 51.3576 16.5124 51.203 16.5124 51.027Z'
            fill='#8C8B88'
          />
          <path
            d='M88.0807 51.4641C88.2193 51.4641 88.3447 51.4428 88.4567 51.4001C88.5687 51.3521 88.654 51.2828 88.7127 51.1921C88.7767 51.0961 88.8087 50.9788 88.8087 50.8401C88.8087 50.7335 88.782 50.6375 88.7287 50.5521C88.6807 50.4668 88.6087 50.4028 88.5127 50.3601C88.422 50.3121 88.3153 50.2881 88.1927 50.2881C88.0113 50.2881 87.8567 50.3361 87.7287 50.4321C87.6007 50.5228 87.526 50.6455 87.5047 50.8001L86.2087 50.7521C86.2407 50.4375 86.3447 50.1628 86.5207 49.9281C86.6967 49.6935 86.9287 49.5121 87.2167 49.3841C87.5047 49.2561 87.83 49.1921 88.1927 49.1921C88.5713 49.1921 88.9047 49.2561 89.1927 49.3841C89.486 49.5121 89.71 49.6908 89.8647 49.9201C90.0247 50.1441 90.1047 50.4055 90.1047 50.7041C90.1047 51.0615 89.9713 51.3575 89.7047 51.5921C89.438 51.8215 89.086 51.9575 88.6487 52.0001L88.6567 51.8641C88.9927 51.8801 89.2833 51.9628 89.5287 52.1121C89.7793 52.2561 89.9687 52.4428 90.0967 52.6721C90.23 52.8961 90.2967 53.1308 90.2967 53.3761C90.2967 53.7121 90.206 54.0135 90.0247 54.2801C89.8487 54.5468 89.6007 54.7548 89.2807 54.9041C88.966 55.0535 88.6033 55.1281 88.1927 55.1281C87.8193 55.1281 87.4727 55.0588 87.1527 54.9201C86.838 54.7815 86.582 54.5788 86.3847 54.3121C86.1927 54.0401 86.0887 53.7175 86.0727 53.3441L87.3687 53.2961C87.3847 53.5201 87.47 53.6988 87.6247 53.8321C87.7847 53.9655 87.974 54.0321 88.1927 54.0321C88.3527 54.0321 88.494 54.0028 88.6167 53.9441C88.7393 53.8855 88.8327 53.8028 88.8967 53.6961C88.966 53.5895 89.0007 53.4641 89.0007 53.3201C89.0007 53.1495 88.9633 53.0028 88.8887 52.8801C88.814 52.7521 88.7073 52.6561 88.5687 52.5921C88.43 52.5281 88.2673 52.4961 88.0807 52.4961L87.7767 52.5041V51.4561L88.0807 51.4641Z'
            fill='#8C8B88'
          />
          <path
            d='M53.1305 84.6979C53.0879 84.5486 52.9999 84.4286 52.8665 84.3379C52.7385 84.2473 52.5865 84.2019 52.4105 84.2019C52.0692 84.2019 51.8079 84.3699 51.6265 84.7059C51.4452 85.0366 51.3492 85.5273 51.3385 86.1779L50.8585 86.1539C50.9812 85.8233 51.1839 85.5566 51.4665 85.3539C51.7545 85.1459 52.0665 85.0419 52.4025 85.0419C52.7865 85.0419 53.1279 85.1273 53.4265 85.2979C53.7305 85.4633 53.9652 85.6979 54.1305 86.0019C54.3012 86.3006 54.3865 86.6419 54.3865 87.0259C54.3865 87.4206 54.2959 87.7699 54.1145 88.0739C53.9332 88.3779 53.6799 88.6153 53.3545 88.7859C53.0345 88.9566 52.6665 89.0419 52.2505 89.0419C51.8932 89.0419 51.5705 88.9699 51.2825 88.8259C50.9945 88.6766 50.7545 88.4606 50.5625 88.1779C50.4132 87.9593 50.2985 87.7006 50.2185 87.4019C50.1385 87.1033 50.0985 86.7726 50.0985 86.4099C50.0985 85.7753 50.1732 85.2126 50.3225 84.7219C50.4772 84.2313 50.7252 83.8393 51.0665 83.5459C51.4132 83.2526 51.8612 83.1059 52.4105 83.1059C52.7199 83.1059 53.0079 83.1699 53.2745 83.2979C53.5465 83.4259 53.7759 83.6073 53.9625 83.8419C54.1545 84.0713 54.2879 84.3379 54.3625 84.6419L53.1305 84.6979ZM51.4345 87.0259C51.4345 87.2073 51.4665 87.3673 51.5305 87.5059C51.5999 87.6446 51.6959 87.7539 51.8185 87.8339C51.9465 87.9086 52.0905 87.9459 52.2505 87.9459C52.4105 87.9459 52.5545 87.9086 52.6825 87.8339C52.8159 87.7539 52.9172 87.6446 52.9865 87.5059C53.0559 87.3673 53.0905 87.2073 53.0905 87.0259C53.0905 86.8499 53.0559 86.6953 52.9865 86.5619C52.9225 86.4286 52.8292 86.3246 52.7065 86.2499C52.5892 86.1753 52.4559 86.1379 52.3065 86.1379C52.1359 86.1379 51.9839 86.1753 51.8505 86.2499C51.7225 86.3246 51.6212 86.4286 51.5465 86.5619C51.4719 86.6953 51.4345 86.8499 51.4345 87.0259Z'
            fill='#8C8B88'
          />
          <g filter='url(#filter1_i_11652_20397)'>
            <circle
              cx='51.8707'
              cy='51.8795'
              r='4.39655'
              fill='#2A2C32'
            />
          </g>
          <g filter='url(#filter2_di_11652_20397)'>
            <rect
              x='19.4316'
              y='52.9908'
              width='3'
              height='33'
              rx='1.5'
              transform='rotate(-90 19.4316 52.9908)'
              fill='#2A2C32'
            />
          </g>
          <g filter='url(#filter3_d_11652_20397)'>
            <rect
              x='77.8589'
              y='48.2598'
              width='3'
              height='24.9269'
              rx='1.5'
              transform='rotate(85.7685 77.8589 48.2598)'
              fill='#2A2C32'
            />
          </g>
          <g filter='url(#filter4_di_11652_20397)'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M38.0332 92.7395C38.1295 92.7938 38.2515 92.7481 38.2886 92.644L51.693 54.9778C51.7516 54.9811 51.8106 54.9828 51.8699 54.9828C53.5839 54.9828 54.9734 53.5933 54.9734 51.8793C54.9734 50.8454 54.4678 49.9295 53.6903 49.3656L55.1925 45.1444C55.2948 44.857 55.1274 44.544 54.8317 44.4694L54.0204 44.2648C53.756 44.1981 53.4853 44.3481 53.4016 44.6076L52.056 48.7814C51.9944 48.7777 51.9324 48.7759 51.8699 48.7759C50.1559 48.7759 48.7665 50.1654 48.7665 51.8793C48.7665 52.9833 49.3429 53.9527 50.2113 54.5028L37.951 92.5295C37.925 92.6101 37.9594 92.6979 38.0332 92.7395Z'
              fill='#EC4A4A'
            />
          </g>
          <path
            d='M89.8743 25.9483C92.8204 24.4314 94.0074 20.7883 92.152 18.0428C89.2609 13.7647 85.724 9.94535 81.6573 6.72566C76.0185 2.26128 69.4911 -0.947139 62.512 -2.68486C55.5329 -4.42257 48.2631 -4.6495 41.1893 -3.35046C34.1154 -2.05141 27.4006 0.743641 21.4943 4.84763C15.588 8.95162 10.6264 14.2699 6.94164 20.4464C3.2569 26.623 0.93398 33.5154 0.128359 40.6623C-0.677261 47.8092 0.0530023 55.0457 2.27027 61.8876C3.86936 66.8219 6.21527 71.4688 9.21629 75.6705C11.1423 78.367 14.9706 78.5252 17.4198 76.2932L49.7227 46.8546C50.1129 46.4991 50.5483 46.1966 51.0176 45.9549L89.8743 25.9483Z'
            fill='white'
            fillOpacity='0.2'
          />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_dddi_11652_20397'
          x='0.922246'
          y='0.672414'
          width='102.155'
          height='104.224'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood
            floodOpacity='0'
            result='BackgroundImageFix'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1.55172' />
          <feGaussianBlur stdDeviation='0.517241' />
          <feComposite
            in2='hardAlpha'
            operator='out'
          />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_11652_20397'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='-1.03448' />
          <feGaussianBlur stdDeviation='0.646552' />
          <feComposite
            in2='hardAlpha'
            operator='out'
          />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.687305 0 0 0 0 0.687305 0 0 0 0 0.687305 0 0 0 0.4 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_11652_20397'
            result='effect2_dropShadow_11652_20397'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1.81034' />
          <feGaussianBlur stdDeviation='1.03448' />
          <feComposite
            in2='hardAlpha'
            operator='out'
          />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.8 0'
          />
          <feBlend
            mode='normal'
            in2='effect2_dropShadow_11652_20397'
            result='effect3_dropShadow_11652_20397'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect3_dropShadow_11652_20397'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2.58621' />
          <feGaussianBlur stdDeviation='2.06897' />
          <feComposite
            in2='hardAlpha'
            operator='arithmetic'
            k2='-1'
            k3='1'
          />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.441602 0 0 0 0 0.441602 0 0 0 0 0.441602 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect4_innerShadow_11652_20397'
          />
        </filter>
        <filter
          id='filter1_i_11652_20397'
          x='47.4741'
          y='47.4829'
          width='8.79297'
          height='9.09309'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood
            floodOpacity='0'
            result='BackgroundImageFix'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='0.3' />
          <feGaussianBlur stdDeviation='0.3' />
          <feComposite
            in2='hardAlpha'
            operator='arithmetic'
            k2='-1'
            k3='1'
          />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_11652_20397'
          />
        </filter>
        <filter
          id='filter2_di_11652_20397'
          x='17.4316'
          y='49.9908'
          width='37'
          height='9'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood
            floodOpacity='0'
            result='BackgroundImageFix'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite
            in2='hardAlpha'
            operator='out'
          />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_11652_20397'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_11652_20397'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='0.3' />
          <feGaussianBlur stdDeviation='0.3' />
          <feComposite
            in2='hardAlpha'
            operator='arithmetic'
            k2='-1'
            k3='1'
          />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_11652_20397'
          />
        </filter>
        <filter
          id='filter3_d_11652_20397'
          x='51'
          y='48.2598'
          width='29.0801'
          height='10.8311'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood
            floodOpacity='0'
            result='BackgroundImageFix'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite
            in2='hardAlpha'
            operator='out'
          />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_11652_20397'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_11652_20397'
            result='shape'
          />
        </filter>
        <filter
          id='filter4_di_11652_20397'
          x='35.9424'
          y='44.249'
          width='21.2803'
          height='52.5135'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood
            floodOpacity='0'
            result='BackgroundImageFix'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite
            in2='hardAlpha'
            operator='out'
          />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_11652_20397'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_11652_20397'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='0.517241' />
          <feGaussianBlur stdDeviation='0.258621' />
          <feComposite
            in2='hardAlpha'
            operator='arithmetic'
            k2='-1'
            k3='1'
          />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect2_innerShadow_11652_20397'
          />
        </filter>
        <clipPath id='clip0_11652_20397'>
          <rect
            x='2.99121'
            y='3'
            width='98.0172'
            height='98.0172'
            rx='49.0086'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
