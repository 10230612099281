import styled from 'styled-components';

export const Container = styled.div`
  line-height: 125%;
  max-width: 100%;
  min-height: 100vh;
  display: grid;
  // grid-template-areas: 'sidebar padding content';
  grid-template-areas: 'sidebar content';
  // grid-template-columns: min-content 16px 1fr;
  grid-template-columns: min-content 1fr;

  &.messenger {
    @media (max-width: 1130px) {
      max-width: 1187px;
    }
    @media (max-width: 1920px) {
      max-width: 1187px;
    }
  }
  &.clients {
    @media (max-width: 1130px) {
      max-width: 1432px;
    }
    @media (max-width: 1920px) {
      max-width: 1432px;
    }
  }
`;

export const Content = styled.div`
  height: 100%;
`;

export const ExpireTarif = styled.div`
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  background: ${(props) => props.theme.status.errorFill};
  color: ${(props) => props.theme.color.base01};
  p {
    color: ${(props) => props.theme.color.base01};
  }
  svg {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
`;

export const MainContent = styled.div`
  position: relative;
  width: 100%;
  transition: background 0.3s ease;
  grid-area: content;
  justify-self: center;
  overflow: hidden;
  &.none {
    //overflow: visible;
  }
`;
export const Wrapper = styled.div`
  /* //overflow: hidden; */
  height: 100%;
  // box-shadow: 0 0 15px 0 #69696921;
  position: relative;
  transition: background 0.3s ease;
  z-index: 0;
`;
