import styled from 'styled-components';
import { MODAL_GAP } from '../../../../../../styles';
export const fullHd = 48;
export const laptop = 42;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;
export const Wrapper = styled.div`
  overflow-x: auto;
`;
export const Box = styled.div`
  width: 300px;
  justify-content: flex-end;
  margin-top: 0;
  @media (max-width: 1500px) {
    /* margin-top: 20px; */
  }
`;
export const Slot = styled.div`
  background: ${(props) => props.theme.color.secondaryLight};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 1500px) {
    font-size: 11px;
  }
  border-radius: 4px;
  &:hover {
    background: ${(props) => props.theme.color.success};
    opacity: 1;
  }
  .weekday-side {
    p {
      text-align: center;
    }
  }
  .fill {
    gap: 0;
    font-size: 10px;
    font-weight: 600;
    span {
      color: #3fa66e;
    }
  }
  &.disabled {
    pointer-events: none;
    position: relative;
    opacity: 0.4;
    > div {
      &:before {
        content: '';
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
        z-index: 2;
      }
    }
  }
`;

export const TimeSlotMobile = styled.div`
  position: absolute;
  z-index: 54;
  top: 46px;
  width: 42px;
  left: 0;
  &:before {
    content: '';
    position: absolute;
    width: 42px;
    height: 100%;
    background: ${(props) => props.theme.color.secondaryLight};
    top: -46px;
    left: 0;
  }
`;

export const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(17, ${fullHd}px);
  grid-template-rows: repeat(8, ${fullHd}px);
  grid-gap: 7px;
  margin-bottom: 40px;
  @media (max-width: 1500px) {
    margin-bottom: 20px;
    grid-template-columns: repeat(17, ${laptop}px);
    grid-template-rows: repeat(8, ${laptop}px);
  }
`;
export const Hours = styled.div`
  grid-column: 1 / 18;
  position: relative;
  grid-row: 1;
  display: grid;
  grid-template-columns: repeat(25, ${fullHd}px);
  gap: 7px;
  @media (max-width: 1500px) {
    grid-template-columns: repeat(25, ${laptop}px);
  }
  ${Slot}:first-of-type {
    visibility: hidden;
  }
`;
export const Side = styled.div`
  grid-column: 1;
  grid-row: 2 / 8;
  display: grid;
  grid-template-rows: repeat(7, ${fullHd}px);
  gap: 7px;
  position: relative;
  font-size: 12px;
  div {
    display: flex;
    gap: 7px;
    div {
      width: ${fullHd}px;
      @media (max-width: 1500px) {
        width: ${laptop}px;
      }
    }
  }
  @media (max-width: 1500px) {
    grid-template-rows: repeat(7, ${laptop}px);
  }
`;
export const LoaderLayout = styled.div`
  position: absolute;
  inset: 0 0 0 0;
  background: ${(props) => props.theme.color.mainLight}1A;
  z-index: 10;
`;
export const Event = styled.div`
  //width: 48px;
  height: ${fullHd}px;
  @media (max-width: 1500px) {
    height: ${laptop}px;
  }
  border-radius: 4px;
  background: #c9f5d0;
  width: ${(props: any) => props.width}px !important;
  top: 0;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  z-index: 25;
  left: 0;
  &:hover {
    opacity: 1;
  }
`;

export const BtnControlWrap = styled.div`
  padding-left: 85px;
  margin-top: auto;
  display: flex;
  gap: ${MODAL_GAP};
`;
