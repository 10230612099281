import React, { forwardRef } from 'react';

import { ModalProps, ModalType } from './Modal.props';
import { ModalContainer, ModalContent, ModalWrapper } from './Modal.styled';

import { cn, useModal } from '../../../lib';
import { useTypedSelector } from '../../../lib/hooks/useTypedSelector';
import { Overlay } from '../../Overlay/Overlay';
import { Portal } from '../../Portal';

const ANIMATION_DELAY = 300;

export const Modal = forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
  const { className, children, isOpen, onClose, lazy, type, size, btnColor, isInsideCloseButton } = props;
  const { closeOnOverlayClick } = useTypedSelector((state) => state.modal);
  const { close, isClosing, isMounted } = useModal({
    animationDelay: ANIMATION_DELAY,
    onClose,
    isOpen
  });

  const handleClose = () => {
    if (closeOnOverlayClick) {
      close();
    }
  };

  if (lazy && !isMounted) {
    return null;
  }

  return (
    <>
      <Portal element={document.getElementById('app') ?? document.body}>
        <ModalWrapper
          size={size}
          className={cn(
            type === ModalType.SIDE || type === ModalType.SIDE_OVERLAY ? 'side' : type === ModalType.TOP && 'top',
            type === ModalType.SIDE_OVERLAY && 'side-overlay',
            type === ModalType.SIDE_OVERLAY_TRANSPARENT && 'side-overlay-transparent side',
            className
          )}
          ref={ref}
        >
          <Overlay
            type={type}
            className={cn({ isClosing })}
            onClick={handleClose}
          />

          <ModalContainer
            size={size}
            className={cn(
              type === ModalType.SIDE || type === ModalType.SIDE_OVERLAY
                ? 'side'
                : type === ModalType.SIDE_OVERLAY_TRANSPARENT
                ? 'side-transparent'
                : type === ModalType.TOP && 'top'
            )}
          >
            <ModalContent
              className={cn(
                { isClosing },
                (type === ModalType.SIDE || type === ModalType.SIDE_OVERLAY) && 'side',
                type === ModalType.SIDE_OVERLAY && 'side-overlay',
                type === ModalType.SIDE_OVERLAY_TRANSPARENT && 'side-overlay-transparent side-transparent'
              )}
              size={size}
            >
              {children}
            </ModalContent>
          </ModalContainer>
        </ModalWrapper>
      </Portal>
    </>
  );
});

Modal.displayName = 'Modal';
